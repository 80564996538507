import type * as Types from '../../types/generated/types';

import {
  useQuery,
  useMutation,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
  type UseMutationOptions,
} from '@tanstack/react-query';
export const StayRoomDetailsFragmentDoc = `
    fragment StayRoomDetails on StayHHonorsActivityRoomDetail {
  _id
  bonusPoints
  bonusPointsFmt
  cxlNumber
  guestActivityType
  roomSeries
  roomTypeName
  roomTypeNameFmt: roomTypeName @truncate(byWords: true, length: 3)
  bookAgainUrl
  usedPointsFmt(language: $language)
  transactions {
    transactionId
    transactionType
    partnerName
    baseEarningOption
    guestActivityPointsType
    description
    descriptionFmt: description @toTitleCase
    basePoints
    basePointsFmt
    bonusPoints
    bonusPointsFmt
    status
    usedPointsFmt(language: $language)
  }
  totalPoints
  totalPointsFmt(language: $language)
  viewFolioUrl(type: link)
}
    `;
export const StayTransactionFragmentDoc = `
    fragment StayTransaction on StayHHonorsTransaction {
  transactionId
  transactionType
  partnerName
  baseEarningOption
  guestActivityPointsType
  description
  descriptionFmt: description @toTitleCase
  basePoints
  basePointsFmt
  bonusPoints
  bonusPointsFmt
  earnedPoints
  earnedPointsFmt
  status
  usedPoints
  usedPointsFmt(language: $language)
  hiltonForBusiness {
    _id
    h4bFlag
    h4bName
  }
}
    `;
export const StayActivitySummaryFragmentDoc = `
    fragment StayActivitySummary on StayHHonorsActivitySummary {
  numRooms
  _id
  stayId
  arrivalDate
  departureDate
  hotelName
  desc
  descFmt: desc @toTitleCase
  guestActivityType
  ctyhocn
  brandCode
  roomDetails(sort: {by: roomSeries, order: asc}) {
    ...StayRoomDetails
    transactions {
      ...StayTransaction
    }
  }
  transactions {
    ...StayTransaction
  }
  bookAgainUrl
  checkinUrl
  confNumber
  cxlNumber
  timeframe
  lengthOfStay
  viewFolioUrl
  viewOrEditReservationUrl
  earnedPoints
  earnedPointsFmt
  totalPoints
  totalPointsFmt
  usedPoints
  usedPointsFmt
}
    ${StayRoomDetailsFragmentDoc}
${StayTransactionFragmentDoc}`;
export const FullWidthImageFragmentDoc = `
    fragment FullWidthImage on BrandPage {
  fullWidthImage {
    headline
    label
    shortDescription
    link {
      adaDescription
      isNewWindow
      label
      url
    }
    imageCompound {
      image {
        altText
        variants {
          size
          url
        }
      }
    }
  }
}
    `;
export const RotatingTilesFragmentDoc = `
    fragment RotatingTiles on BrandPage {
  rotatingTiles {
    description
    headline
    items {
      headline
      label
      shortDescription
      image {
        altText
        variants {
          size
          url
        }
      }
      label
      link {
        adaDescription
        isNewWindow
        label
        url
      }
    }
  }
}
    `;
export const MosaicGridFragmentDoc = `
    fragment MosaicGrid on BrandPage {
  mosaicGrid {
    description
    headline
    id
    items {
      label
      headline
      shortDescription
      image {
        altText
        variants {
          size
          url
        }
      }
      link {
        adaDescription
        isNewWindow
        label
        url
      }
    }
  }
}
    `;
export const FourXGridFragmentDoc = `
    fragment FourXGrid on BrandPage {
  fourXGrid {
    headline
    description
    items {
      _id
      headline
      label
      shortDescription
      segmentIds
      link {
        adaDescription
        isNewWindow
        label
        url
      }
      imageCompound {
        expansionPanelImage {
          variants(sort: {by: size, order: desc}) {
            url
            size
          }
        }
        image {
          altText
          variants(sort: {by: size, order: desc}) {
            url
            size
          }
        }
      }
    }
  }
}
    `;
export const StaysFieldsFragmentFragmentDoc = `
    fragment StaysFieldsFragment on StaySummary {
  id: confNumber
  arrivalDate
  arrivalDateFmt
  autoUpgradedStay
  bookingCode
  brandCode
  confNumber
  ctyhocn
  departureDate
  departureDateFmt
  desc
  guestBenefits {
    foodAndBeverageCreditBenefit {
      description
      linkLabel
      linkUrl
    }
  }
  isStayUpsell
  isStayUpsellOverAutoUpgrade
  lengthOfStay
  manageEligible
  originResId
  resCreateDate
  resCreateDateFmt
  resStatus
  stayId
  stayStatus
  bookAgainUrl
  viewOrEditReservationUrl
  showAutoUpgradeIndicator
  hotel {
    images {
      master(imageVariant: honorsPropertyImageThumbnail) {
        altText
        variants {
          size
          url
        }
      }
      carousel(imageVariant: searchPropertyCarousel) {
        altText
        variants {
          size
          url
        }
      }
    }
    ctyhocn
    name
    contactInfo {
      phoneNumber
    }
    display {
      treatments
    }
    galleryImages(sort: {by: seq, order: asc}) {
      alt
      src
      hiResSrc(width: $galleryImageWidth, height: $galleryImageHeight)
    }
  }
}
    `;
export const ActivePromotionFragmentDoc = `
    fragment ActivePromotion on GuestHHonorsActivePromotion {
  promotionCode
  promotionCounters {
    promotionProgress
    promotionType
  }
  promotionDaysLeft
  promotionDescription: promotionAlternativeDescription
  promotionAlternativeDescriptionTruncated: promotionAlternativeDescription @truncate(length: 65)
  promotionEndDate
  promotionName
  termsAndConditions
}
    `;
export const EligiblePromotionFragmentDoc = `
    fragment EligiblePromotion on GuestHHonorsEligiblePromotion {
  callCenterOnly
  endDate
  promotionCode
  promotionDescription: alternateDescription
  alternateDescriptionTruncated: alternateDescription @truncate(length: 65)
  promotionName
  termsAndConditions
}
    `;
export const Guest_MissingStaysDocument = {
  operationName: 'guest_MissingStays',
  operationString: `query guest_MissingStays($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      hhonorsNumber
    }
  }
}`,
  originalOpName: 'guest_MissingStays',
};
export function useGuest_MissingStaysQuery<TData = Types.Guest_MissingStaysQuery, TError = unknown>(
  variables: Types.Guest_MissingStaysQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_MissingStaysQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_MissingStaysQuery, TError, TData>({
    queryKey: [Guest_MissingStaysDocument, variables],
    ...options,
  });
}
export function serverSideGuest_MissingStaysQuery(
  queryClient: QueryClient,
  variables: Types.Guest_MissingStaysQueryVariables,
  options?: FetchQueryOptions<Types.Guest_MissingStaysQuery>
) {
  return queryClient.fetchQuery<Types.Guest_MissingStaysQuery>({
    queryKey: [Guest_MissingStaysDocument, variables],
    ...options,
  });
}

export const HotelDocument = {
  operationName: 'hotel',
  operationString: `query hotel($ctyhocn: String!, $language: String!) {
  hotel(ctyhocn: $ctyhocn, language: $language) {
    address {
      country
      state
      city
      addressFmt(format: "stacked")
    }
    localization {
      currencyCode
    }
    name
    brandCode
  }
}`,
  originalOpName: 'hotel',
};
export function useHotelQuery<TData = Types.HotelQuery, TError = unknown>(
  variables: Types.HotelQueryVariables,
  options?: Omit<UseQueryOptions<Types.HotelQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HotelQuery, TError, TData>({
    queryKey: [HotelDocument, variables],
    ...options,
  });
}
export function serverSideHotelQuery(
  queryClient: QueryClient,
  variables: Types.HotelQueryVariables,
  options?: FetchQueryOptions<Types.HotelQuery>
) {
  return queryClient.fetchQuery<Types.HotelQuery>({
    queryKey: [HotelDocument, variables],
    ...options,
  });
}

export const Guest_GuestActivitySummaryOptions_HotelDocument = {
  operationName: 'guest_guestActivitySummaryOptions_hotel',
  operationString: `query guest_guestActivitySummaryOptions_hotel($guestId: BigInt!, $language: String!, $startDate: String!, $endDate: String!, $guestActivityTypes: [GuestActivityType], $sort: [StayHHonorsActivitySummarySortInput!], $first: Int, $after: String, $guestActivityDisplayType: GuestActivityDisplayType) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      summary {
        tierName
        totalPoints
      }
    }
    activitySummaryOptions(
      input: {groupMultiRoomStays: true, startDate: $startDate, endDate: $endDate, guestActivityTypes: $guestActivityTypes, guestActivityDisplayType: $guestActivityDisplayType}
    ) {
      _guestActivitiesSummary {
        totalSize
        size
        start
        end
        nextCursor
        prevCursor
      }
      guestActivitiesSummary(sort: $sort, first: $first, after: $after) {
        ...StayActivitySummary
      }
    }
  }
}

      ${StayActivitySummaryFragmentDoc}`,
  originalOpName: 'guest_guestActivitySummaryOptions_hotel',
};
export function useGuest_GuestActivitySummaryOptions_HotelQuery<
  TData = Types.Guest_GuestActivitySummaryOptions_HotelQuery,
  TError = unknown
>(
  variables: Types.Guest_GuestActivitySummaryOptions_HotelQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Guest_GuestActivitySummaryOptions_HotelQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Guest_GuestActivitySummaryOptions_HotelQuery, TError, TData>({
    queryKey: [Guest_GuestActivitySummaryOptions_HotelDocument, variables],
    ...options,
  });
}
export function serverSideGuest_GuestActivitySummaryOptions_HotelQuery(
  queryClient: QueryClient,
  variables: Types.Guest_GuestActivitySummaryOptions_HotelQueryVariables,
  options?: FetchQueryOptions<Types.Guest_GuestActivitySummaryOptions_HotelQuery>
) {
  return queryClient.fetchQuery<Types.Guest_GuestActivitySummaryOptions_HotelQuery>({
    queryKey: [Guest_GuestActivitySummaryOptions_HotelDocument, variables],
    ...options,
  });
}

export const Countries_AddressOptionsDocument = {
  operationName: 'countries_AddressOptions',
  operationString: `query countries_AddressOptions($language: String!, $code: String, $type: AddressOptionsAddressType) {
  countries(language: $language, filter: {code: $code}) {
    code
    name
    addressOptions(type: $type) {
      name
      label
      maxLength
      required
    }
    states(sort: {by: name, order: asc}) {
      value: code
      name
    }
  }
}`,
  originalOpName: 'countries_AddressOptions',
};
export function useCountries_AddressOptionsQuery<
  TData = Types.Countries_AddressOptionsQuery,
  TError = unknown
>(
  variables: Types.Countries_AddressOptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Countries_AddressOptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Countries_AddressOptionsQuery, TError, TData>({
    queryKey: [Countries_AddressOptionsDocument, variables],
    ...options,
  });
}
export function serverSideCountries_AddressOptionsQuery(
  queryClient: QueryClient,
  variables: Types.Countries_AddressOptionsQueryVariables,
  options?: FetchQueryOptions<Types.Countries_AddressOptionsQuery>
) {
  return queryClient.fetchQuery<Types.Countries_AddressOptionsQuery>({
    queryKey: [Countries_AddressOptionsDocument, variables],
    ...options,
  });
}

export const GeocodeDocument = {
  operationName: 'geocode',
  operationString: `query geocode($postalCode: String, $language: String!) {
  geocode(language: $language, postalCode: $postalCode) {
    match {
      address {
        city
        state
      }
    }
  }
}`,
  originalOpName: 'geocode',
};
export function useGeocodeQuery<TData = Types.GeocodeQuery, TError = unknown>(
  variables: Types.GeocodeQueryVariables,
  options?: Omit<UseQueryOptions<Types.GeocodeQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GeocodeQuery, TError, TData>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}
export function serverSideGeocodeQuery(
  queryClient: QueryClient,
  variables: Types.GeocodeQueryVariables,
  options?: FetchQueryOptions<Types.GeocodeQuery>
) {
  return queryClient.fetchQuery<Types.GeocodeQuery>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}

export const Brand_CmsTextHeadlinerDocument = {
  operationName: 'brand_CmsTextHeadliner',
  operationString: `query brand_CmsTextHeadliner($language: String!, $path: String!) {
  brand(brandCode: "HH", language: $language) {
    page(path: $path) {
      textHeadliner {
        items {
          headline
          label
          segmentIds
          link {
            adaDescription
            campaignId
            isNewWindow
            label
            url
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brand_CmsTextHeadliner',
};
export function useBrand_CmsTextHeadlinerQuery<
  TData = Types.Brand_CmsTextHeadlinerQuery,
  TError = unknown
>(
  variables: Types.Brand_CmsTextHeadlinerQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_CmsTextHeadlinerQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_CmsTextHeadlinerQuery, TError, TData>({
    queryKey: [Brand_CmsTextHeadlinerDocument, variables],
    ...options,
  });
}
export function serverSideBrand_CmsTextHeadlinerQuery(
  queryClient: QueryClient,
  variables: Types.Brand_CmsTextHeadlinerQueryVariables,
  options?: FetchQueryOptions<Types.Brand_CmsTextHeadlinerQuery>
) {
  return queryClient.fetchQuery<Types.Brand_CmsTextHeadlinerQuery>({
    queryKey: [Brand_CmsTextHeadlinerDocument, variables],
    ...options,
  });
}

export const BrandDocument = {
  operationName: 'brand',
  operationString: `query brand($language: String!, $path: String!, $brandCode: String!) {
  brand(brandCode: $brandCode, language: $language) {
    page(path: $path) {
      fullWidthImage {
        caption
        captionLink
        headline
        id
        imageCompound {
          expansionPanelImage {
            altText
            variants {
              size
              url
            }
          }
          image {
            altText
            variants {
              size
              url
            }
          }
        }
        label
        link {
          adaDescription
          campaignId
          isNewWindow
          label
          url
        }
        segmentIds
        shortDescription
        attributes
        description
      }
    }
  }
}`,
  originalOpName: 'brand',
};
export function useBrandQuery<TData = Types.BrandQuery, TError = unknown>(
  variables: Types.BrandQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandQuery, TError, TData>({
    queryKey: [BrandDocument, variables],
    ...options,
  });
}
export function serverSideBrandQuery(
  queryClient: QueryClient,
  variables: Types.BrandQueryVariables,
  options?: FetchQueryOptions<Types.BrandQuery>
) {
  return queryClient.fetchQuery<Types.BrandQuery>({
    queryKey: [BrandDocument, variables],
    ...options,
  });
}

export const Brand_HiltonForBusinessDocument = {
  operationName: 'brand',
  operationString: `query brand($language: String!, $brandCode: String!, $path: String!) {
  brand(language: $language, brandCode: $brandCode) {
    page(path: $path) {
      textBlock {
        description {
          value
        }
        heading {
          value
        }
      }
    }
  }
}`,
  originalOpName: 'brand_HiltonForBusiness',
};
export function useBrand_HiltonForBusinessQuery<
  TData = Types.Brand_HiltonForBusinessQuery,
  TError = unknown
>(
  variables: Types.Brand_HiltonForBusinessQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_HiltonForBusinessQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_HiltonForBusinessQuery, TError, TData>({
    queryKey: [Brand_HiltonForBusinessDocument, variables],
    ...options,
  });
}
export function serverSideBrand_HiltonForBusinessQuery(
  queryClient: QueryClient,
  variables: Types.Brand_HiltonForBusinessQueryVariables,
  options?: FetchQueryOptions<Types.Brand_HiltonForBusinessQuery>
) {
  return queryClient.fetchQuery<Types.Brand_HiltonForBusinessQuery>({
    queryKey: [Brand_HiltonForBusinessDocument, variables],
    ...options,
  });
}

export const Brand_HotelBenefitsDocument = {
  operationName: 'brand_HotelBenefits',
  operationString: `query brand_HotelBenefits($language: String!, $path: String!) {
  brand(brandCode: "HH", language: $language) {
    page(path: $path) {
      textBlock {
        description {
          value
        }
      }
      tabbedIcons {
        headline
        description
        items {
          description
          iconType
          label
        }
      }
    }
  }
}`,
  originalOpName: 'brand_HotelBenefits',
};
export function useBrand_HotelBenefitsQuery<
  TData = Types.Brand_HotelBenefitsQuery,
  TError = unknown
>(
  variables: Types.Brand_HotelBenefitsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_HotelBenefitsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_HotelBenefitsQuery, TError, TData>({
    queryKey: [Brand_HotelBenefitsDocument, variables],
    ...options,
  });
}
export function serverSideBrand_HotelBenefitsQuery(
  queryClient: QueryClient,
  variables: Types.Brand_HotelBenefitsQueryVariables,
  options?: FetchQueryOptions<Types.Brand_HotelBenefitsQuery>
) {
  return queryClient.fetchQuery<Types.Brand_HotelBenefitsQuery>({
    queryKey: [Brand_HotelBenefitsDocument, variables],
    ...options,
  });
}

export const BrandRewardProgramDocument = {
  operationName: 'brand',
  operationString: `query brand($brandCode: String!, $language: String!, $path: String!) {
  brand(brandCode: $brandCode, language: $language) {
    page(path: $path) {
      rotatingTiles {
        items {
          _id
          headline
          shortDescription
        }
      }
      multicolumnText {
        description
        headline
        subheading
        items {
          headline
          items {
            contentBlock {
              description {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      imageHeadliner {
        items {
          headline
          label
          link {
            isNewWindow
            label
            url
            adaDescription
            campaignId
          }
        }
      }
      halfAndHalf {
        description
        headline
        items {
          headline
          shortDescription
        }
      }
      textBlock {
        id
        heading {
          value
        }
        description {
          value
        }
      }
      carousel {
        items {
          multimediaCompound {
            items {
              _id
              videoUrl
              videoName
              videoAutoPlay
              posterImageUrl
              orientation
              metadata {
                label
                url
                language
                type
              }
            }
          }
          headline
          link {
            campaignId
            isNewWindow
            adaDescription
            label
            url
          }
          shortDescription
        }
      }
    }
  }
}`,
  originalOpName: 'brandRewardProgram',
};
export function useBrandRewardProgramQuery<TData = Types.BrandRewardProgramQuery, TError = unknown>(
  variables: Types.BrandRewardProgramQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandRewardProgramQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandRewardProgramQuery, TError, TData>({
    queryKey: [BrandRewardProgramDocument, variables],
    ...options,
  });
}
export function serverSideBrandRewardProgramQuery(
  queryClient: QueryClient,
  variables: Types.BrandRewardProgramQueryVariables,
  options?: FetchQueryOptions<Types.BrandRewardProgramQuery>
) {
  return queryClient.fetchQuery<Types.BrandRewardProgramQuery>({
    queryKey: [BrandRewardProgramDocument, variables],
    ...options,
  });
}

export const Brand_ThreeSixNineGridDocument = {
  operationName: 'brand',
  operationString: `query brand($language: String!, $brandCode: String!, $path: String!) {
  brand(language: $language, brandCode: $brandCode) {
    page(path: $path) {
      threeSixNineGrid {
        description
        headline
        items {
          _id
          headline
          label
          shortDescription
          segmentIds
          imageCompound {
            image {
              altText
              variants {
                size
                url
              }
            }
            expansionPanelImage {
              altText
              variants {
                size
                url
              }
            }
          }
          link {
            adaDescription
            campaignId
            isNewWindow
            label
            url
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brand_ThreeSixNineGrid',
};
export function useBrand_ThreeSixNineGridQuery<
  TData = Types.Brand_ThreeSixNineGridQuery,
  TError = unknown
>(
  variables: Types.Brand_ThreeSixNineGridQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_ThreeSixNineGridQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_ThreeSixNineGridQuery, TError, TData>({
    queryKey: [Brand_ThreeSixNineGridDocument, variables],
    ...options,
  });
}
export function serverSideBrand_ThreeSixNineGridQuery(
  queryClient: QueryClient,
  variables: Types.Brand_ThreeSixNineGridQueryVariables,
  options?: FetchQueryOptions<Types.Brand_ThreeSixNineGridQuery>
) {
  return queryClient.fetchQuery<Types.Brand_ThreeSixNineGridQuery>({
    queryKey: [Brand_ThreeSixNineGridDocument, variables],
    ...options,
  });
}

export const Brand_UsePointsDocument = {
  operationName: 'brand_UsePoints',
  operationString: `query brand_UsePoints($language: String!) {
  brand(brandCode: "HH", language: $language) {
    page(path: "member-home") {
      pointsToolbar {
        description
        headline
        items {
          _id
          headline
          label
          shortDescription
          segmentIds
          link {
            adaDescription
            campaignId
            isNewWindow
            label
            url
          }
          imageCompound {
            image {
              altText
              variants {
                size
                url
              }
            }
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brand_UsePoints',
};
export function useBrand_UsePointsQuery<TData = Types.Brand_UsePointsQuery, TError = unknown>(
  variables: Types.Brand_UsePointsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_UsePointsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_UsePointsQuery, TError, TData>({
    queryKey: [Brand_UsePointsDocument, variables],
    ...options,
  });
}
export function serverSideBrand_UsePointsQuery(
  queryClient: QueryClient,
  variables: Types.Brand_UsePointsQueryVariables,
  options?: FetchQueryOptions<Types.Brand_UsePointsQuery>
) {
  return queryClient.fetchQuery<Types.Brand_UsePointsQuery>({
    queryKey: [Brand_UsePointsDocument, variables],
    ...options,
  });
}

export const Brand_AlertDocument = {
  operationName: 'brand_Alert',
  operationString: `query brand_Alert($language: String!) {
  alerts: brand(brandCode: "HH", language: $language) {
    messages(sort: {by: startDateTime, order: desc}) {
      type
      headline
      content: shortDescription
      link {
        adaDescription
        isNewWindow
        label
        url
      }
    }
  }
}`,
  originalOpName: 'brand_Alert',
};
export function useBrand_AlertQuery<TData = Types.Brand_AlertQuery, TError = unknown>(
  variables: Types.Brand_AlertQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_AlertQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_AlertQuery, TError, TData>({
    queryKey: [Brand_AlertDocument, variables],
    ...options,
  });
}
export function serverSideBrand_AlertQuery(
  queryClient: QueryClient,
  variables: Types.Brand_AlertQueryVariables,
  options?: FetchQueryOptions<Types.Brand_AlertQuery>
) {
  return queryClient.fetchQuery<Types.Brand_AlertQuery>({
    queryKey: [Brand_AlertDocument, variables],
    ...options,
  });
}

export const Brand_CmsAlertDocument = {
  operationName: 'brand_CmsAlert',
  operationString: `query brand_CmsAlert($language: String!, $path: String!) {
  brand(brandCode: "HH", language: $language) {
    page(path: $path) {
      textHeadliner {
        items {
          headline
          label
          link {
            adaDescription
            isNewWindow
            label
            url
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brand_CmsAlert',
};
export function useBrand_CmsAlertQuery<TData = Types.Brand_CmsAlertQuery, TError = unknown>(
  variables: Types.Brand_CmsAlertQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_CmsAlertQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_CmsAlertQuery, TError, TData>({
    queryKey: [Brand_CmsAlertDocument, variables],
    ...options,
  });
}
export function serverSideBrand_CmsAlertQuery(
  queryClient: QueryClient,
  variables: Types.Brand_CmsAlertQueryVariables,
  options?: FetchQueryOptions<Types.Brand_CmsAlertQuery>
) {
  return queryClient.fetchQuery<Types.Brand_CmsAlertQuery>({
    queryKey: [Brand_CmsAlertDocument, variables],
    ...options,
  });
}

export const Brand_CmsImageHeadlinerDocument = {
  operationName: 'brand',
  operationString: `query brand($language: String!, $path: String!) {
  brand(brandCode: "HH", language: $language) {
    page(path: $path) {
      imageHeadliner {
        id
        items {
          id
          headline
          label
          segmentIds
          link {
            campaignId
            adaDescription
            isNewWindow
            label
            url
          }
          image {
            altText
            variants {
              size
              url
            }
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brand_CmsImageHeadliner',
};
export function useBrand_CmsImageHeadlinerQuery<
  TData = Types.Brand_CmsImageHeadlinerQuery,
  TError = unknown
>(
  variables: Types.Brand_CmsImageHeadlinerQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_CmsImageHeadlinerQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_CmsImageHeadlinerQuery, TError, TData>({
    queryKey: [Brand_CmsImageHeadlinerDocument, variables],
    ...options,
  });
}
export function serverSideBrand_CmsImageHeadlinerQuery(
  queryClient: QueryClient,
  variables: Types.Brand_CmsImageHeadlinerQueryVariables,
  options?: FetchQueryOptions<Types.Brand_CmsImageHeadlinerQuery>
) {
  return queryClient.fetchQuery<Types.Brand_CmsImageHeadlinerQuery>({
    queryKey: [Brand_CmsImageHeadlinerDocument, variables],
    ...options,
  });
}

export const CmsBrandMemberDataDocument = {
  operationName: 'cmsBrandMemberData',
  operationString: `query cmsBrandMemberData($language: String!, $brandCode: String!) {
  brand(language: $language, brandCode: $brandCode) {
    page(path: "guest::points") {
      tabbedIcons {
        id
        description
        headline
        items {
          _id
          label
          iconType
          description
          headline
          link {
            adaDescription
            isNewWindow
            label
            url
          }
        }
      }
      fourXGrid {
        headline
        description
        link {
          _id
          adaDescription
          isNewWindow
          label
          url
        }
        items {
          shortDescription
          headline
          _id
          link {
            _id
            adaDescription
            isNewWindow
            label
            url
          }
          imageCompound {
            image {
              variants(filter: {size: md}) {
                size
                url
              }
            }
          }
          label
          caption
          segmentIds
        }
      }
    }
  }
}`,
  originalOpName: 'cmsBrandMemberData',
};
export function useCmsBrandMemberDataQuery<TData = Types.CmsBrandMemberDataQuery, TError = unknown>(
  variables: Types.CmsBrandMemberDataQueryVariables,
  options?: Omit<UseQueryOptions<Types.CmsBrandMemberDataQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CmsBrandMemberDataQuery, TError, TData>({
    queryKey: [CmsBrandMemberDataDocument, variables],
    ...options,
  });
}
export function serverSideCmsBrandMemberDataQuery(
  queryClient: QueryClient,
  variables: Types.CmsBrandMemberDataQueryVariables,
  options?: FetchQueryOptions<Types.CmsBrandMemberDataQuery>
) {
  return queryClient.fetchQuery<Types.CmsBrandMemberDataQuery>({
    queryKey: [CmsBrandMemberDataDocument, variables],
    ...options,
  });
}

export const CmsFaqDocument = {
  operationName: 'faq',
  operationString: `query faq($language: String!, $faqType: FaqType!) {
  faq(language: $language, faqType: $faqType) {
    title
    subHeading
    shortDescription
    subTopic {
      subTopicName
      faqContent {
        _id
        question
        answer(format: md)
        answerOrderedList(format: md)
        answerUnorderedList(format: md)
      }
    }
  }
}`,
  originalOpName: 'cmsFaq',
};
export function useCmsFaqQuery<TData = Types.CmsFaqQuery, TError = unknown>(
  variables: Types.CmsFaqQueryVariables,
  options?: Omit<UseQueryOptions<Types.CmsFaqQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CmsFaqQuery, TError, TData>({
    queryKey: [CmsFaqDocument, variables],
    ...options,
  });
}
export function serverSideCmsFaqQuery(
  queryClient: QueryClient,
  variables: Types.CmsFaqQueryVariables,
  options?: FetchQueryOptions<Types.CmsFaqQuery>
) {
  return queryClient.fetchQuery<Types.CmsFaqQuery>({
    queryKey: [CmsFaqDocument, variables],
    ...options,
  });
}

export const CmsPolicyDocument = {
  operationName: 'policy',
  operationString: `query policy($language: String!, $policy: PolicyDocumentType!) {
  policy(language: $language, policyDocumentType: $policy) {
    name
    description
    shortDescription
    content {
      _id
      description(format: md)
      heading(format: md)
      orderedList(format: md)
      unorderedList(format: md)
    }
  }
}`,
  originalOpName: 'cmsPolicy',
};
export function useCmsPolicyQuery<TData = Types.CmsPolicyQuery, TError = unknown>(
  variables: Types.CmsPolicyQueryVariables,
  options?: Omit<UseQueryOptions<Types.CmsPolicyQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CmsPolicyQuery, TError, TData>({
    queryKey: [CmsPolicyDocument, variables],
    ...options,
  });
}
export function serverSideCmsPolicyQuery(
  queryClient: QueryClient,
  variables: Types.CmsPolicyQueryVariables,
  options?: FetchQueryOptions<Types.CmsPolicyQuery>
) {
  return queryClient.fetchQuery<Types.CmsPolicyQuery>({
    queryKey: [CmsPolicyDocument, variables],
    ...options,
  });
}

export const Countries_SubscriptionsDocument = {
  operationName: 'countries_Subscriptions',
  operationString: `query countries_Subscriptions($countryFilter: CountryFilterInput, $language: String!) {
  countries(filter: $countryFilter, first: 1, language: $language) {
    name
    code
    dataSubjectRights
    marketingConsent
    marketingOptIn
    marketingOptInForHGV
  }
}`,
  originalOpName: 'countries_Subscriptions',
};
export function useCountries_SubscriptionsQuery<
  TData = Types.Countries_SubscriptionsQuery,
  TError = unknown
>(
  variables: Types.Countries_SubscriptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Countries_SubscriptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Countries_SubscriptionsQuery, TError, TData>({
    queryKey: [Countries_SubscriptionsDocument, variables],
    ...options,
  });
}
export function serverSideCountries_SubscriptionsQuery(
  queryClient: QueryClient,
  variables: Types.Countries_SubscriptionsQueryVariables,
  options?: FetchQueryOptions<Types.Countries_SubscriptionsQuery>
) {
  return queryClient.fetchQuery<Types.Countries_SubscriptionsQuery>({
    queryKey: [Countries_SubscriptionsDocument, variables],
    ...options,
  });
}

export const CountriesDocument = {
  operationName: 'countries',
  operationString: `query countries($language: String!, $CountrySortInput: [CountrySortInput!]) {
  countries(
    language: $language
    sort: $CountrySortInput
    filter: {prohibited: false}
  ) {
    name: displayName
    callingCode
    code
  }
}`,
  originalOpName: 'countries',
};
export function useCountriesQuery<TData = Types.CountriesQuery, TError = unknown>(
  variables: Types.CountriesQueryVariables,
  options?: Omit<UseQueryOptions<Types.CountriesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CountriesQuery, TError, TData>({
    queryKey: [CountriesDocument, variables],
    ...options,
  });
}
export function serverSideCountriesQuery(
  queryClient: QueryClient,
  variables: Types.CountriesQueryVariables,
  options?: FetchQueryOptions<Types.CountriesQuery>
) {
  return queryClient.fetchQuery<Types.CountriesQuery>({
    queryKey: [CountriesDocument, variables],
    ...options,
  });
}

export const CreateGuest2FaDocument = {
  operationName: 'createGuest2FA',
  operationString: `mutation createGuest2FA($deliveryId: Int!, $deliveryMethod: Guest2FADeliveryMethod!, $guestId: BigInt!, $language: String!, $totp: String) {
  createGuest2FA(
    deliveryId: $deliveryId
    deliveryMethod: $deliveryMethod
    guestId: $guestId
    language: $language
    totp: $totp
  ) {
    data
    error {
      code
      context
      message
      message_noTx: message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGuest2FA',
};
export function useCreateGuest2FaMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuest2FaMutation,
    TError,
    [typeof CreateGuest2FaDocument, Types.CreateGuest2FaMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuest2FaMutation,
    TError,
    [typeof CreateGuest2FaDocument, Types.CreateGuest2FaMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestCombineAccountsDocument = {
  operationName: 'createGuestCombineAccounts',
  operationString: `mutation createGuestCombineAccounts($input: GuestCombineAccountsInput!) {
  createGuestCombineAccounts(input: $input) {
    data
    error {
      message
    }
  }
}`,
  originalOpName: 'createGuestCombineAccounts',
};
export function useCreateGuestCombineAccountsMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestCombineAccountsMutation,
    TError,
    [typeof CreateGuestCombineAccountsDocument, Types.CreateGuestCombineAccountsMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestCombineAccountsMutation,
    TError,
    [typeof CreateGuestCombineAccountsDocument, Types.CreateGuestCombineAccountsMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestGdprDocument = {
  operationName: 'createGuestGdpr',
  operationString: `mutation createGuestGdpr($language: String!, $guestGdprInput: GuestGdprInput!, $recaptchaInput: RecaptchaInput) {
  createGuestGdpr(
    language: $language
    guestGdprInput: $guestGdprInput
    recaptchaInput: $recaptchaInput
  ) {
    data
    error {
      message
      code
    }
  }
}`,
  originalOpName: 'createGuestGdpr',
};
export function useCreateGuestGdprMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestGdprMutation,
    TError,
    [typeof CreateGuestGdprDocument, Types.CreateGuestGdprMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestGdprMutation,
    TError,
    [typeof CreateGuestGdprDocument, Types.CreateGuestGdprMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestMissingStayDocument = {
  operationName: 'createGuestMissingStay',
  operationString: `mutation createGuestMissingStay($input: GuestMissingStayInput!) {
  createGuestMissingStay(input: $input) {
    data
    error {
      message
    }
    notifications {
      message
      title
    }
  }
}`,
  originalOpName: 'createGuestMissingStay',
};
export function useCreateGuestMissingStayMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestMissingStayMutation,
    TError,
    [typeof CreateGuestMissingStayDocument, Types.CreateGuestMissingStayMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestMissingStayMutation,
    TError,
    [typeof CreateGuestMissingStayDocument, Types.CreateGuestMissingStayMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestPointsRedemptionDocument = {
  operationName: 'createGuestPointsRedemption',
  operationString: `mutation createGuestPointsRedemption($guestId: BigInt!, $input: GuestHHonorsPointsRedemptionInput!, $mfaInput: MFAInput) {
  createGuestPointsRedemption(
    guestId: $guestId
    input: $input
    mfaInput: $mfaInput
  ) {
    data
    error {
      code
      context
      message
      message_noTx: message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGuestPointsRedemption',
};
export function useCreateGuestPointsRedemptionMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestPointsRedemptionMutation,
    TError,
    [
      typeof CreateGuestPointsRedemptionDocument,
      Types.CreateGuestPointsRedemptionMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestPointsRedemptionMutation,
    TError,
    [
      typeof CreateGuestPointsRedemptionDocument,
      Types.CreateGuestPointsRedemptionMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestPriceMatchGuaranteeDocument = {
  operationName: 'createGuestPriceMatchGuarantee',
  operationString: `mutation createGuestPriceMatchGuarantee($input: GuestPriceMatchGuaranteeInput!) {
  createGuestPriceMatchGuarantee(input: $input) {
    data
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGuestPriceMatchGuarantee',
};
export function useCreateGuestPriceMatchGuaranteeMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestPriceMatchGuaranteeMutation,
    TError,
    [
      typeof CreateGuestPriceMatchGuaranteeDocument,
      Types.CreateGuestPriceMatchGuaranteeMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestPriceMatchGuaranteeMutation,
    TError,
    [
      typeof CreateGuestPriceMatchGuaranteeDocument,
      Types.CreateGuestPriceMatchGuaranteeMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestPromoRegistrationDocument = {
  operationName: 'createGuestPromoRegistration',
  operationString: `mutation createGuestPromoRegistration($guestId: BigInt!, $promotionCode: String!) {
  createGuestPromoRegistration(guestId: $guestId, promotionCode: $promotionCode) {
    data {
      active(filter: {promotionCode: $promotionCode}) {
        promotionCode
        promotionCounters {
          promotionProgress
          promotionType
        }
        promotionDaysLeft
        promotionDescription: promotionAlternativeDescription
        promotionAlternativeDescriptionTruncated: promotionAlternativeDescription @truncate(length: 65)
        promotionEndDate
        promotionName
        termsAndConditions
      }
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGuestPromoRegistration',
};
export function useCreateGuestPromoRegistrationMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestPromoRegistrationMutation,
    TError,
    [
      typeof CreateGuestPromoRegistrationDocument,
      Types.CreateGuestPromoRegistrationMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestPromoRegistrationMutation,
    TError,
    [
      typeof CreateGuestPromoRegistrationDocument,
      Types.CreateGuestPromoRegistrationMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const CreateGuestSharePersonalInfoOptOutDocument = {
  operationName: 'createGuestSharePersonalInfoOptOut',
  operationString: `mutation createGuestSharePersonalInfoOptOut($input: GuestSharePersonalInfoOptOutInput!, $language: String!) {
  createGuestSharePersonalInfoOptOut(language: $language, input: $input) {
    data
    error {
      message
      code
    }
  }
}`,
  originalOpName: 'createGuestSharePersonalInfoOptOut',
};
export function useCreateGuestSharePersonalInfoOptOutMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestSharePersonalInfoOptOutMutation,
    TError,
    [
      typeof CreateGuestSharePersonalInfoOptOutDocument,
      Types.CreateGuestSharePersonalInfoOptOutMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestSharePersonalInfoOptOutMutation,
    TError,
    [
      typeof CreateGuestSharePersonalInfoOptOutDocument,
      Types.CreateGuestSharePersonalInfoOptOutMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const CreditCardOffersDocument = {
  operationName: 'creditCardOffers',
  operationString: `query creditCardOffers($language: String!) {
  creditCardOffers(language: $language) {
    description
    headline
    cardName
    cardType
    items {
      cardName
      cardType
      attributes
      offerDescription
      offerTitle
      segmentIds
      builtinBenefitsBlocks {
        description
        heading
        orderedList
        unorderedList
      }
      cardBreakdownBlocks {
        description
        heading
        orderedList
        unorderedList
      }
      earningPointsBlocks {
        description
        heading
        orderedList
        unorderedList
      }
      feeDescription
      images {
        altText
        brandCodes
        image {
          image {
            variants {
              tabs(sort: {by: name}) {
                cdnLink
                name
              }
            }
          }
        }
      }
      link {
        adaDescription
        campaignId
        isNewWindow
        linkLabel
        linkUrl
      }
    }
  }
}`,
  originalOpName: 'creditCardOffers',
};
export function useCreditCardOffersQuery<TData = Types.CreditCardOffersQuery, TError = unknown>(
  variables: Types.CreditCardOffersQueryVariables,
  options?: Omit<UseQueryOptions<Types.CreditCardOffersQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CreditCardOffersQuery, TError, TData>({
    queryKey: [CreditCardOffersDocument, variables],
    ...options,
  });
}
export function serverSideCreditCardOffersQuery(
  queryClient: QueryClient,
  variables: Types.CreditCardOffersQueryVariables,
  options?: FetchQueryOptions<Types.CreditCardOffersQuery>
) {
  return queryClient.fetchQuery<Types.CreditCardOffersQuery>({
    queryKey: [CreditCardOffersDocument, variables],
    ...options,
  });
}

export const CreditCardTypesDocument = {
  operationName: 'creditCardTypes',
  operationString: `query creditCardTypes($language: String!) {
  creditCardTypes(language: $language, filter: {hhonors: true}) {
    code
    name
  }
}`,
  originalOpName: 'creditCardTypes',
};
export function useCreditCardTypesQuery<TData = Types.CreditCardTypesQuery, TError = unknown>(
  variables: Types.CreditCardTypesQueryVariables,
  options?: Omit<UseQueryOptions<Types.CreditCardTypesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CreditCardTypesQuery, TError, TData>({
    queryKey: [CreditCardTypesDocument, variables],
    ...options,
  });
}
export function serverSideCreditCardTypesQuery(
  queryClient: QueryClient,
  variables: Types.CreditCardTypesQueryVariables,
  options?: FetchQueryOptions<Types.CreditCardTypesQuery>
) {
  return queryClient.fetchQuery<Types.CreditCardTypesQuery>({
    queryKey: [CreditCardTypesDocument, variables],
    ...options,
  });
}

export const CurrenciesDocument = {
  operationName: 'currencies',
  operationString: `query currencies($language: String) {
  currencies(language: $language, sort: {by: numericCode, order: asc}) {
    description
    currencyCode
  }
}`,
  originalOpName: 'currencies',
};
export function useCurrenciesQuery<TData = Types.CurrenciesQuery, TError = unknown>(
  variables?: Types.CurrenciesQueryVariables,
  options?: Omit<UseQueryOptions<Types.CurrenciesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.CurrenciesQuery, TError, TData>({
    queryKey: [CurrenciesDocument, variables],
    ...options,
  });
}
export function serverSideCurrenciesQuery(
  queryClient: QueryClient,
  variables?: Types.CurrenciesQueryVariables,
  options?: FetchQueryOptions<Types.CurrenciesQuery>
) {
  return queryClient.fetchQuery<Types.CurrenciesQuery>({
    queryKey: [CurrenciesDocument, variables],
    ...options,
  });
}

export const Languages_DefaultCountryCodeDocument = {
  operationName: 'languages_defaultCountryCode',
  operationString: `query languages_defaultCountryCode($language: String!) {
  languages(language: $language, filter: {languageCodeOHW: $language}) {
    defaultCountryCode
  }
}`,
  originalOpName: 'languages_defaultCountryCode',
};
export function useLanguages_DefaultCountryCodeQuery<
  TData = Types.Languages_DefaultCountryCodeQuery,
  TError = unknown
>(
  variables: Types.Languages_DefaultCountryCodeQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Languages_DefaultCountryCodeQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Languages_DefaultCountryCodeQuery, TError, TData>({
    queryKey: [Languages_DefaultCountryCodeDocument, variables],
    ...options,
  });
}
export function serverSideLanguages_DefaultCountryCodeQuery(
  queryClient: QueryClient,
  variables: Types.Languages_DefaultCountryCodeQueryVariables,
  options?: FetchQueryOptions<Types.Languages_DefaultCountryCodeQuery>
) {
  return queryClient.fetchQuery<Types.Languages_DefaultCountryCodeQuery>({
    queryKey: [Languages_DefaultCountryCodeDocument, variables],
    ...options,
  });
}

export const DeleteGuest2FaDocument = {
  operationName: 'deleteGuest2FA',
  operationString: `mutation deleteGuest2FA($deliveryId: Int!, $deliveryMethod: Guest2FADeliveryMethod!, $guestId: BigInt!, $language: String!, $totp: String) {
  deleteGuest2FA(
    deliveryId: $deliveryId
    deliveryMethod: $deliveryMethod
    guestId: $guestId
    language: $language
    totp: $totp
  ) {
    data
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'deleteGuest2FA',
};
export function useDeleteGuest2FaMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.DeleteGuest2FaMutation,
    TError,
    [typeof DeleteGuest2FaDocument, Types.DeleteGuest2FaMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.DeleteGuest2FaMutation,
    TError,
    [typeof DeleteGuest2FaDocument, Types.DeleteGuest2FaMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const DeleteGuestAccountDocument = {
  operationName: 'deleteGuestAccount',
  operationString: `mutation deleteGuestAccount($guestId: BigInt!, $input: GuestDeleteAccountInput!, $language: String!) {
  deleteGuestAccount(guestId: $guestId, language: $language, input: $input) {
    data
    error {
      code
      message
      message_noTx: message
    }
  }
}`,
  originalOpName: 'deleteGuestAccount',
};
export function useDeleteGuestAccountMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.DeleteGuestAccountMutation,
    TError,
    [typeof DeleteGuestAccountDocument, Types.DeleteGuestAccountMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.DeleteGuestAccountMutation,
    TError,
    [typeof DeleteGuestAccountDocument, Types.DeleteGuestAccountMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const DeleteGuestAddressDocument = {
  operationName: 'deleteGuestAddress',
  operationString: `mutation deleteGuestAddress($addressId: Int!, $guestId: BigInt!, $language: String!) {
  deleteGuestAddress(
    addressId: $addressId
    guestId: $guestId
    language: $language
  ) {
    data {
      addressId
    }
    error {
      code
    }
  }
}`,
  originalOpName: 'deleteGuestAddress',
};
export function useDeleteGuestAddressMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.DeleteGuestAddressMutation,
    TError,
    [typeof DeleteGuestAddressDocument, Types.DeleteGuestAddressMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.DeleteGuestAddressMutation,
    TError,
    [typeof DeleteGuestAddressDocument, Types.DeleteGuestAddressMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const DeleteGuestEmailDocument = {
  operationName: 'deleteGuestEmail',
  operationString: `mutation deleteGuestEmail($emailId: Int!, $guestId: BigInt!, $language: String!, $totp: String!) {
  deleteGuestEmail(
    emailId: $emailId
    guestId: $guestId
    language: $language
    mfaInput: {totp: $totp}
  ) {
    data {
      emailId
    }
    error {
      code
      message
      message_noTx: message
      notifications {
        code
        message
      }
    }
  }
}`,
  originalOpName: 'deleteGuestEmail',
};
export function useDeleteGuestEmailMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.DeleteGuestEmailMutation,
    TError,
    [typeof DeleteGuestEmailDocument, Types.DeleteGuestEmailMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.DeleteGuestEmailMutation,
    TError,
    [typeof DeleteGuestEmailDocument, Types.DeleteGuestEmailMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const DeleteGuestPaymentMethodDocument = {
  operationName: 'deleteGuestPaymentMethod',
  operationString: `mutation deleteGuestPaymentMethod($guestId: BigInt!, $language: String!, $paymentId: Int!) {
  deleteGuestPaymentMethod(
    guestId: $guestId
    language: $language
    paymentId: $paymentId
  ) {
    data {
      cardNumberMasked
    }
    error {
      code
      notifications {
        message
      }
    }
  }
}`,
  originalOpName: 'deleteGuestPaymentMethod',
};
export function useDeleteGuestPaymentMethodMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.DeleteGuestPaymentMethodMutation,
    TError,
    [typeof DeleteGuestPaymentMethodDocument, Types.DeleteGuestPaymentMethodMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.DeleteGuestPaymentMethodMutation,
    TError,
    [typeof DeleteGuestPaymentMethodDocument, Types.DeleteGuestPaymentMethodMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const DeleteGuestPhoneDocument = {
  operationName: 'deleteGuestPhone',
  operationString: `mutation deleteGuestPhone($phoneId: Int!, $guestId: BigInt!, $language: String!, $totp: String!) {
  deleteGuestPhone(
    phoneId: $phoneId
    guestId: $guestId
    language: $language
    mfaInput: {totp: $totp}
  ) {
    data {
      phoneId
    }
    error {
      code
      message
      message_noTx: message
      notifications {
        code
        message
      }
    }
  }
}`,
  originalOpName: 'deleteGuestPhone',
};
export function useDeleteGuestPhoneMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.DeleteGuestPhoneMutation,
    TError,
    [typeof DeleteGuestPhoneDocument, Types.DeleteGuestPhoneMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.DeleteGuestPhoneMutation,
    TError,
    [typeof DeleteGuestPhoneDocument, Types.DeleteGuestPhoneMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const Guest_SubscriptionsDocument = {
  operationName: 'guest_Subscriptions',
  operationString: `query guest_Subscriptions($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    hhonors {
      hhonorsNumber
      summary {
        tier
        tierName
      }
    }
    personalinfo {
      emails(filter: {preferred: true}) {
        emailAddressMasked
      }
      addresses(filter: {preferred: true}) {
        postalCode
        countryName
        country
      }
    }
    subscriptions {
      optOuts {
        survey
        marketing
        global
      }
      marketingSubscriptions
      hhonorsSubscriptions
    }
  }
}`,
  originalOpName: 'guest_Subscriptions',
};
export function useGuest_SubscriptionsQuery<
  TData = Types.Guest_SubscriptionsQuery,
  TError = unknown
>(
  variables: Types.Guest_SubscriptionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_SubscriptionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_SubscriptionsQuery, TError, TData>({
    queryKey: [Guest_SubscriptionsDocument, variables],
    ...options,
  });
}
export function serverSideGuest_SubscriptionsQuery(
  queryClient: QueryClient,
  variables: Types.Guest_SubscriptionsQueryVariables,
  options?: FetchQueryOptions<Types.Guest_SubscriptionsQuery>
) {
  return queryClient.fetchQuery<Types.Guest_SubscriptionsQuery>({
    queryKey: [Guest_SubscriptionsDocument, variables],
    ...options,
  });
}

export const FeatureConfigsDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs($names: [String!]) {
  featureConfigs(names: $names) {
    id: name
    name
    conditions
    config
  }
}`,
  originalOpName: 'featureConfigs',
};
export function useFeatureConfigsQuery<TData = Types.FeatureConfigsQuery, TError = unknown>(
  variables?: Types.FeatureConfigsQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureConfigsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureConfigsQuery, TError, TData>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}
export function serverSideFeatureConfigsQuery(
  queryClient: QueryClient,
  variables?: Types.FeatureConfigsQueryVariables,
  options?: FetchQueryOptions<Types.FeatureConfigsQuery>
) {
  return queryClient.fetchQuery<Types.FeatureConfigsQuery>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}

export const FeatureTogglesDocument = {
  operationName: 'featureToggles',
  operationString: `query featureToggles($flags: [String]!) {
  featureToggles(names: $flags) {
    id: name
    name
    enabled
  }
}`,
  originalOpName: 'featureToggles',
};
export function useFeatureTogglesQuery<TData = Types.FeatureTogglesQuery, TError = unknown>(
  variables: Types.FeatureTogglesQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureTogglesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureTogglesQuery, TError, TData>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}
export function serverSideFeatureTogglesQuery(
  queryClient: QueryClient,
  variables: Types.FeatureTogglesQueryVariables,
  options?: FetchQueryOptions<Types.FeatureTogglesQuery>
) {
  return queryClient.fetchQuery<Types.FeatureTogglesQuery>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}

export const Geocode_AddressDocument = {
  operationName: 'geocode_address',
  operationString: `query geocode_address($address: String, $language: String!, $placeId: String, $sessionToken: String) {
  geocode(
    language: $language
    address: $address
    placeId: $placeId
    sessionToken: $sessionToken
  ) {
    match {
      id
      name
      placeUri
      type
    }
  }
}`,
  originalOpName: 'geocode_address',
};
export function useGeocode_AddressQuery<TData = Types.Geocode_AddressQuery, TError = unknown>(
  variables: Types.Geocode_AddressQueryVariables,
  options?: Omit<UseQueryOptions<Types.Geocode_AddressQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Geocode_AddressQuery, TError, TData>({
    queryKey: [Geocode_AddressDocument, variables],
    ...options,
  });
}
export function serverSideGeocode_AddressQuery(
  queryClient: QueryClient,
  variables: Types.Geocode_AddressQueryVariables,
  options?: FetchQueryOptions<Types.Geocode_AddressQuery>
) {
  return queryClient.fetchQuery<Types.Geocode_AddressQuery>({
    queryKey: [Geocode_AddressDocument, variables],
    ...options,
  });
}

export const Brand_CountriesDocument = {
  operationName: 'brand_countries',
  operationString: `query brand_countries($after: String, $containsHotels: Boolean, $first: Int, $language: String!, $countryFilter: CountryFilterInput, $regionFilter: CountryStateFilterInput, $cityFilter: CountryCityFilterInput, $getStates: Boolean = false, $getCities: Boolean = false, $getMarketing: Boolean = false, $getCCSMarketing: Boolean = false) {
  countries(
    after: $after
    containsHotels: $containsHotels
    filter: $countryFilter
    first: $first
    language: $language
    sort: {by: name}
  ) {
    code
    callingCode
    dataSubjectRights
    marketingConsent @include(if: $getMarketing)
    marketingOptIn @include(if: $getMarketing)
    marketingOptInForHGV @include(if: $getMarketing)
    marketingOptin @include(if: $getCCSMarketing) {
      dataSubjectRights
      marketingOptinRules {
        category
        optIns {
          autoOptIn
          _id
          customerTextExternal
          name
          subscriptionCodes
          termsAndConditionsBook
          termsAndConditionsJoin
        }
      }
    }
    name: displayName
    states(filter: $regionFilter) @include(if: $getStates) {
      name
      code
      personalDataRequest
      value: code
      cities(filter: $cityFilter, sort: {by: name, order: asc}) @include(if: $getCities) {
        name
        value: name
        brandCodes
      }
    }
    cities(filter: $cityFilter, onlyIfNoStates: true, sort: {by: name, order: asc}) @include(if: $getCities) {
      name
      value: name
      brandCodes
    }
  }
}`,
  originalOpName: 'brand_countries',
};
export function useBrand_CountriesQuery<TData = Types.Brand_CountriesQuery, TError = unknown>(
  variables: Types.Brand_CountriesQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_CountriesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_CountriesQuery, TError, TData>({
    queryKey: [Brand_CountriesDocument, variables],
    ...options,
  });
}
export function serverSideBrand_CountriesQuery(
  queryClient: QueryClient,
  variables: Types.Brand_CountriesQueryVariables,
  options?: FetchQueryOptions<Types.Brand_CountriesQuery>
) {
  return queryClient.fetchQuery<Types.Brand_CountriesQuery>({
    queryKey: [Brand_CountriesDocument, variables],
    ...options,
  });
}

export const Brand_NonMemberDocument = {
  operationName: 'brand_NonMember',
  operationString: `query brand_NonMember($brandCode: String!, $language: String!, $shouldIncludeFourXGrid: Boolean!) {
  brand(brandCode: $brandCode, language: $language) {
    page(path: "/") {
      ...FullWidthImage
      ...RotatingTiles
      ...MosaicGrid
      ...FourXGrid @include(if: $shouldIncludeFourXGrid)
    }
  }
}

      ${FullWidthImageFragmentDoc}
${RotatingTilesFragmentDoc}
${MosaicGridFragmentDoc}
${FourXGridFragmentDoc}`,
  originalOpName: 'brand_NonMember',
};
export function useBrand_NonMemberQuery<TData = Types.Brand_NonMemberQuery, TError = unknown>(
  variables: Types.Brand_NonMemberQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_NonMemberQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_NonMemberQuery, TError, TData>({
    queryKey: [Brand_NonMemberDocument, variables],
    ...options,
  });
}
export function serverSideBrand_NonMemberQuery(
  queryClient: QueryClient,
  variables: Types.Brand_NonMemberQueryVariables,
  options?: FetchQueryOptions<Types.Brand_NonMemberQuery>
) {
  return queryClient.fetchQuery<Types.Brand_NonMemberQuery>({
    queryKey: [Brand_NonMemberDocument, variables],
    ...options,
  });
}

export const GuestHiltonHonorsNumberDocument = {
  operationName: 'guest',
  operationString: `query guest($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      hhonorsNumber
      isOwner
      isTeamMember
      packages {
        packageName
      }
      isSMBMember
      summary {
        tier
        tierName
        totalPoints
        totalPointsFmt
      }
    }
  }
}`,
  originalOpName: 'guestHiltonHonorsNumber',
};
export function useGuestHiltonHonorsNumberQuery<
  TData = Types.GuestHiltonHonorsNumberQuery,
  TError = unknown
>(
  variables: Types.GuestHiltonHonorsNumberQueryVariables,
  options?: Omit<UseQueryOptions<Types.GuestHiltonHonorsNumberQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GuestHiltonHonorsNumberQuery, TError, TData>({
    queryKey: [GuestHiltonHonorsNumberDocument, variables],
    ...options,
  });
}
export function serverSideGuestHiltonHonorsNumberQuery(
  queryClient: QueryClient,
  variables: Types.GuestHiltonHonorsNumberQueryVariables,
  options?: FetchQueryOptions<Types.GuestHiltonHonorsNumberQuery>
) {
  return queryClient.fetchQuery<Types.GuestHiltonHonorsNumberQuery>({
    queryKey: [GuestHiltonHonorsNumberDocument, variables],
    ...options,
  });
}

export const GuestHonorsCardDocument = {
  operationName: 'guest',
  operationString: `query guest($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      expireDate
      expireDateFmt
      expireDateFmtAccessible: expireDateFmt(format: "long", language: $language)
      hhonorsNumber
      isOwnerHGV
      virtualCard {
        cardTier
        cardHeader
        extendedTier
      }
    }
    personalinfo {
      addlName {
        nameFmt
      }
    }
  }
}`,
  originalOpName: 'guestHonorsCard',
};
export function useGuestHonorsCardQuery<TData = Types.GuestHonorsCardQuery, TError = unknown>(
  variables: Types.GuestHonorsCardQueryVariables,
  options?: Omit<UseQueryOptions<Types.GuestHonorsCardQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GuestHonorsCardQuery, TError, TData>({
    queryKey: [GuestHonorsCardDocument, variables],
    ...options,
  });
}
export function serverSideGuestHonorsCardQuery(
  queryClient: QueryClient,
  variables: Types.GuestHonorsCardQueryVariables,
  options?: FetchQueryOptions<Types.GuestHonorsCardQuery>
) {
  return queryClient.fetchQuery<Types.GuestHonorsCardQuery>({
    queryKey: [GuestHonorsCardDocument, variables],
    ...options,
  });
}

export const GuestHotelBenefitsPageDocument = {
  operationName: 'brand_guest_guestStays_hotel',
  operationString: `query brand_guest_guestStays_hotel($language: String!, $guestId: BigInt!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    hhonors {
      hhonorsNumber
      summary {
        tier
        tierName
        totalPointsFmt
      }
    }
    hotelBenefitOptions(filter: {brandCode_not: "WW"}) {
      brandCode
      brandHeaderText(format: md)
      brandInstructionsText(format: md)
      benefits {
        benefitId
        description
        disabled
        inputType
        selected
        maxNumBenefits
        benefits {
          benefitId
          description
          inputType
          selected
          disabled
          benefits {
            benefitId
          }
        }
      }
    }
    preferences {
      benefitprefs {
        benefitGroup
        benefitId
        benefitValue
        brandCode
      }
    }
    stays(limit: 1, timeframe: future) {
      arrivalDate
      departureDate
      lengthOfStay
      guestBenefits {
        hotelBenefitSummary {
          headline1 @toLowerCase
          headline2 @toLowerCase
          headline3 @toLowerCase
          items {
            _id
            description
            selected
          }
        }
      }
      hotel {
        brand {
          code
          formalName
        }
        images {
          master(imageVariant: galleryThumbnail) {
            altText
            variants(filter: {size: md}) {
              url
            }
          }
        }
        name
      }
    }
  }
}`,
  originalOpName: 'guestHotelBenefitsPage',
};
export function useGuestHotelBenefitsPageQuery<
  TData = Types.GuestHotelBenefitsPageQuery,
  TError = unknown
>(
  variables: Types.GuestHotelBenefitsPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GuestHotelBenefitsPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GuestHotelBenefitsPageQuery, TError, TData>({
    queryKey: [GuestHotelBenefitsPageDocument, variables],
    ...options,
  });
}
export function serverSideGuestHotelBenefitsPageQuery(
  queryClient: QueryClient,
  variables: Types.GuestHotelBenefitsPageQueryVariables,
  options?: FetchQueryOptions<Types.GuestHotelBenefitsPageQuery>
) {
  return queryClient.fetchQuery<Types.GuestHotelBenefitsPageQuery>({
    queryKey: [GuestHotelBenefitsPageDocument, variables],
    ...options,
  });
}

export const Guest_Hotel_MyAccountDocument = {
  operationName: 'guest_hotel_MyAccount',
  operationString: `query guest_hotel_MyAccount($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    personalinfo {
      emails {
        validated
      }
      phones {
        validated
      }
      hasUSAddress: hasAddressWithCountry(countryCodes: ["US"])
    }
    hhonors {
      hhonorsNumber
      isTeamMember
      isLifetimeDiamond
      isSMBMember
      isOwner
      isOwnerHGV
      isAmexCardHolder
      summary {
        tier
        tierName
        nextTier
        requalTier
        pointsExpiration
        tierExpiration
        nextTierName
        totalPointsFmt
        qualifiedNights
        qualifiedNightsNext
        qualifiedPoints
        qualifiedPointsNext
        qualifiedPointsFmt
        qualifiedPointsNextFmt
        qualifiedNightsMaint
        rolledOverNights
        showRequalMaintainMessage
        showRequalDowngradeMessage
        milestones {
          applicableNights
          bonusPoints
          bonusPointsFmt
          bonusPointsNext
          bonusPointsNextFmt
          maxBonusPoints
          maxBonusPointsFmt
          maxNights
          nightsNext
          showMilestoneBonusMessage
        }
      }
      amexCoupons {
        _available {
          totalSize
        }
        _held {
          totalSize
        }
        _used {
          totalSize
        }
        available(sort: {by: startDate, order: asc}) {
          checkInDate
          checkOutDate
          codeMasked
          checkOutDateFmt(language: $language)
          endDate
          endDateFmt(language: $language)
          location
          numberOfNights
          offerName
          points
          rewardType
          startDate
          status
          hotel {
            name
            images {
              master(imageVariant: honorsPropertyImageThumbnail) {
                url
                altText
              }
            }
          }
        }
        held {
          checkInDate
          checkOutDate
          codeMasked
          checkOutDateFmt(language: $language)
          endDate
          endDateFmt(language: $language)
          location
          numberOfNights
          offerName
          points
          rewardType
          startDate
          status
          hotel {
            name
            images {
              master(imageVariant: honorsPropertyImageThumbnail) {
                url
                altText
              }
            }
          }
        }
        used {
          checkInDate
          checkOutDate
          codeMasked
          checkOutDateFmt(language: $language)
          endDate
          endDateFmt(language: $language)
          location
          numberOfNights
          offerName
          points
          rewardType
          startDate
          status
          hotel {
            name
            images {
              master(imageVariant: honorsPropertyImageThumbnail) {
                url
                altText
              }
            }
          }
        }
      }
    }
  }
}`,
  originalOpName: 'guest_hotel_MyAccount',
};
export function useGuest_Hotel_MyAccountQuery<
  TData = Types.Guest_Hotel_MyAccountQuery,
  TError = unknown
>(
  variables: Types.Guest_Hotel_MyAccountQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_Hotel_MyAccountQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_Hotel_MyAccountQuery, TError, TData>({
    queryKey: [Guest_Hotel_MyAccountDocument, variables],
    ...options,
  });
}
export function serverSideGuest_Hotel_MyAccountQuery(
  queryClient: QueryClient,
  variables: Types.Guest_Hotel_MyAccountQueryVariables,
  options?: FetchQueryOptions<Types.Guest_Hotel_MyAccountQuery>
) {
  return queryClient.fetchQuery<Types.Guest_Hotel_MyAccountQuery>({
    queryKey: [Guest_Hotel_MyAccountDocument, variables],
    ...options,
  });
}

export const GuestHotelStaysDocument = {
  operationName: 'guest_guestStays_hotel',
  operationString: `query guest_guestStays_hotel($guestId: BigInt!, $language: String!, $limit: Int!, $galleryImageWidth: Int, $galleryImageHeight: Int) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    pastStays: stays(
      limit: $limit
      timeframe: past
      stayStatuses: [checkinRequested, checkout, confirmed, inHouse]
    ) {
      ...StaysFieldsFragment
    }
    futureStays: stays(
      limit: $limit
      stayStatuses: [checkinRequested, checkout, confirmed, inHouse]
    ) {
      ...StaysFieldsFragment
    }
  }
}

      ${StaysFieldsFragmentFragmentDoc}`,
  originalOpName: 'guestHotelStays',
};
export function useGuestHotelStaysQuery<TData = Types.GuestHotelStaysQuery, TError = unknown>(
  variables: Types.GuestHotelStaysQueryVariables,
  options?: Omit<UseQueryOptions<Types.GuestHotelStaysQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GuestHotelStaysQuery, TError, TData>({
    queryKey: [GuestHotelStaysDocument, variables],
    ...options,
  });
}
export function serverSideGuestHotelStaysQuery(
  queryClient: QueryClient,
  variables: Types.GuestHotelStaysQueryVariables,
  options?: FetchQueryOptions<Types.GuestHotelStaysQuery>
) {
  return queryClient.fetchQuery<Types.GuestHotelStaysQuery>({
    queryKey: [GuestHotelStaysDocument, variables],
    ...options,
  });
}

export const Guest_OffersDocument = {
  operationName: 'guest_Offers',
  operationString: `query guest_Offers($language: String!, $guestId: BigInt!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      promotions {
        active {
          ...ActivePromotion
        }
        eligible {
          ...EligiblePromotion
        }
      }
    }
  }
}

      ${ActivePromotionFragmentDoc}
${EligiblePromotionFragmentDoc}`,
  originalOpName: 'guest_Offers',
};
export function useGuest_OffersQuery<TData = Types.Guest_OffersQuery, TError = unknown>(
  variables: Types.Guest_OffersQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_OffersQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_OffersQuery, TError, TData>({
    queryKey: [Guest_OffersDocument, variables],
    ...options,
  });
}
export function serverSideGuest_OffersQuery(
  queryClient: QueryClient,
  variables: Types.Guest_OffersQueryVariables,
  options?: FetchQueryOptions<Types.Guest_OffersQuery>
) {
  return queryClient.fetchQuery<Types.Guest_OffersQuery>({
    queryKey: [Guest_OffersDocument, variables],
    ...options,
  });
}

export const Guest_PartnersDocument = {
  operationName: 'guest_Partners',
  operationString: `query guest_Partners($language: String!, $guestId: BigInt!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    travelPartners {
      name: partnerName
      code: partnerCode
      type: partnerType
      number: partnerNumber
    }
  }
}`,
  originalOpName: 'guest_Partners',
};
export function useGuest_PartnersQuery<TData = Types.Guest_PartnersQuery, TError = unknown>(
  variables: Types.Guest_PartnersQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_PartnersQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_PartnersQuery, TError, TData>({
    queryKey: [Guest_PartnersDocument, variables],
    ...options,
  });
}
export function serverSideGuest_PartnersQuery(
  queryClient: QueryClient,
  variables: Types.Guest_PartnersQueryVariables,
  options?: FetchQueryOptions<Types.Guest_PartnersQuery>
) {
  return queryClient.fetchQuery<Types.Guest_PartnersQuery>({
    queryKey: [Guest_PartnersDocument, variables],
    ...options,
  });
}

export const Guest_PersonalInfoPageDocument = {
  operationName: 'guest_PersonalInfoPage',
  operationString: `query guest_PersonalInfoPage($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    personalinfo {
      emails(sort: [{by: emailId, order: desc}]) {
        emailId
        emailAddressMasked
        preferred
        validated
      }
      phones(sort: [{by: phoneId, order: desc}]) {
        phoneNumberMasked(format: masked)
        phoneType
        preferred
        phoneId
        phoneCountry
        validated
        phoneNumber2FAStatus
      }
      addresses(sort: [{by: preferred, order: desc}]) {
        addressId
        addressFmt(format: "stacked")
        addressLine1 @toTitleCase
        addressLine2 @toTitleCase
        addressLine3 @toTitleCase
        addressType @toTitleCase
        city @toTitleCase
        state @toUpperCase
        postalCode
        countryName
        country
        preferred
        company @toTitleCase
        addressLocalizations {
          addressFmt(format: "stacked")
          addressLine1
          addressLine2
          addressLine3
          city
          state
          postalCode
          country
          countryName
          company
        }
      }
      paymentMethods(sort: [{by: preferred, order: desc}]) {
        cardName
        cardCode
        cardExpireDate
        cardExpireDateFmt(format: "MM/YY")
        cardNumberLastFour: cardNumberMasked
        cardNumberMaskedFormat: cardNumberMasked(format: masked)
        expired
        paymentId
        preferred
      }
    }
    preferences {
      personalizations {
        preferredLanguage
      }
    }
    hhonors {
      hhonorsNumber
      summary {
        totalPointsFmt
      }
    }
    travelAccounts {
      corporateAccount
      travelAgentNumber
      unlimitedBudgetNumber
      aarpNumber
      aaaNumber
      aaaInternationalNumber
      governmentMilitary
    }
  }
}`,
  originalOpName: 'guest_PersonalInfoPage',
};
export function useGuest_PersonalInfoPageQuery<
  TData = Types.Guest_PersonalInfoPageQuery,
  TError = unknown
>(
  variables: Types.Guest_PersonalInfoPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_PersonalInfoPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_PersonalInfoPageQuery, TError, TData>({
    queryKey: [Guest_PersonalInfoPageDocument, variables],
    ...options,
  });
}
export function serverSideGuest_PersonalInfoPageQuery(
  queryClient: QueryClient,
  variables: Types.Guest_PersonalInfoPageQueryVariables,
  options?: FetchQueryOptions<Types.Guest_PersonalInfoPageQuery>
) {
  return queryClient.fetchQuery<Types.Guest_PersonalInfoPageQuery>({
    queryKey: [Guest_PersonalInfoPageDocument, variables],
    ...options,
  });
}

export const Guest_PreferencesPageDocument = {
  operationName: 'guest',
  operationString: `query guest($language: String!, $guestId: BigInt!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    preferences {
      roomprefs {
        accessible
        bedtype
        closeToElevator
        highFloor
        mostImportant
        smoking
      }
      stayprefs {
        cr_appFavs
        cr_chnFavs
        cr_tmpSetPoint
      }
    }
    travelPartners {
      active
      partnerCode
      partnerName
      partnerNumber
      preferred
    }
  }
}`,
  originalOpName: 'guest_PreferencesPage',
};
export function useGuest_PreferencesPageQuery<
  TData = Types.Guest_PreferencesPageQuery,
  TError = unknown
>(
  variables: Types.Guest_PreferencesPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_PreferencesPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_PreferencesPageQuery, TError, TData>({
    queryKey: [Guest_PreferencesPageDocument, variables],
    ...options,
  });
}
export function serverSideGuest_PreferencesPageQuery(
  queryClient: QueryClient,
  variables: Types.Guest_PreferencesPageQueryVariables,
  options?: FetchQueryOptions<Types.Guest_PreferencesPageQuery>
) {
  return queryClient.fetchQuery<Types.Guest_PreferencesPageQuery>({
    queryKey: [Guest_PreferencesPageDocument, variables],
    ...options,
  });
}

export const Guest_RatesDocument = {
  operationName: 'guest_Rates',
  operationString: `query guest_Rates($guestId: BigInt!, $language: String!, $companyCode: String) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    hhonors {
      redemptions(
        sort: {by: pointsRequired}
        filter: {companyCode: $companyCode, redeemable: true}
      ) {
        code: itemNumber
        name: description @toTitleCase
        itemType
        minimumQuantity
        companyCode
        fulfillmentPathCode
      }
    }
  }
}`,
  originalOpName: 'guest_Rates',
};
export function useGuest_RatesQuery<TData = Types.Guest_RatesQuery, TError = unknown>(
  variables: Types.Guest_RatesQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_RatesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_RatesQuery, TError, TData>({
    queryKey: [Guest_RatesDocument, variables],
    ...options,
  });
}
export function serverSideGuest_RatesQuery(
  queryClient: QueryClient,
  variables: Types.Guest_RatesQueryVariables,
  options?: FetchQueryOptions<Types.Guest_RatesQuery>
) {
  return queryClient.fetchQuery<Types.Guest_RatesQuery>({
    queryKey: [Guest_RatesDocument, variables],
    ...options,
  });
}

export const Guest_GetHHonorsNumberDocument = {
  operationName: 'guest_GetHHonorsNumber',
  operationString: `query guest_GetHHonorsNumber($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    hhonors {
      hhonorsNumber
      packages {
        packageName
      }
      isSMBMember
    }
  }
}`,
  originalOpName: 'guest_GetHHonorsNumber',
};
export function useGuest_GetHHonorsNumberQuery<
  TData = Types.Guest_GetHHonorsNumberQuery,
  TError = unknown
>(
  variables: Types.Guest_GetHHonorsNumberQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_GetHHonorsNumberQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_GetHHonorsNumberQuery, TError, TData>({
    queryKey: [Guest_GetHHonorsNumberDocument, variables],
    ...options,
  });
}
export function serverSideGuest_GetHHonorsNumberQuery(
  queryClient: QueryClient,
  variables: Types.Guest_GetHHonorsNumberQueryVariables,
  options?: FetchQueryOptions<Types.Guest_GetHHonorsNumberQuery>
) {
  return queryClient.fetchQuery<Types.Guest_GetHHonorsNumberQuery>({
    queryKey: [Guest_GetHHonorsNumberDocument, variables],
    ...options,
  });
}

export const Guest_GuestGoHiltonDocument = {
  operationName: 'guest_GuestGoHilton',
  operationString: `query guest_GuestGoHilton($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      hhonorsNumber
      isOwner
      isTeamMember
      packages {
        packageName
      }
      summary {
        tierName
        totalPoints
        totalPointsFmt
      }
    }
    personalinfo {
      name {
        firstName
      }
    }
  }
}`,
  originalOpName: 'guest_GuestGoHilton',
};
export function useGuest_GuestGoHiltonQuery<
  TData = Types.Guest_GuestGoHiltonQuery,
  TError = unknown
>(
  variables: Types.Guest_GuestGoHiltonQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_GuestGoHiltonQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_GuestGoHiltonQuery, TError, TData>({
    queryKey: [Guest_GuestGoHiltonDocument, variables],
    ...options,
  });
}
export function serverSideGuest_GuestGoHiltonQuery(
  queryClient: QueryClient,
  variables: Types.Guest_GuestGoHiltonQueryVariables,
  options?: FetchQueryOptions<Types.Guest_GuestGoHiltonQuery>
) {
  return queryClient.fetchQuery<Types.Guest_GuestGoHiltonQuery>({
    queryKey: [Guest_GuestGoHiltonDocument, variables],
    ...options,
  });
}

export const Guest_OfferDocument = {
  operationName: 'guest_Offer',
  operationString: `query guest_Offer($guestId: BigInt!, $language: String!, $promotionType: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      promotions {
        eligible(filter: {promotionType: $promotionType}, first: 1) {
          callCenterOnly
          endDate
          promotionCode
          promotionDescription: alternateDescription
          alternateDescriptionTruncated: alternateDescription @truncate(length: 65)
          promotionName
          termsAndConditions
        }
      }
    }
  }
}`,
  originalOpName: 'guest_Offer',
};
export function useGuest_OfferQuery<TData = Types.Guest_OfferQuery, TError = unknown>(
  variables: Types.Guest_OfferQueryVariables,
  options?: Omit<UseQueryOptions<Types.Guest_OfferQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Guest_OfferQuery, TError, TData>({
    queryKey: [Guest_OfferDocument, variables],
    ...options,
  });
}
export function serverSideGuest_OfferQuery(
  queryClient: QueryClient,
  variables: Types.Guest_OfferQueryVariables,
  options?: FetchQueryOptions<Types.Guest_OfferQuery>
) {
  return queryClient.fetchQuery<Types.Guest_OfferQuery>({
    queryKey: [Guest_OfferDocument, variables],
    ...options,
  });
}

export const Brand_CmsHgvMaxDocument = {
  operationName: 'brand',
  operationString: `query brand($brandCode: String!, $language: String!, $path: String!) {
  brand(brandCode: $brandCode, language: $language) {
    page(path: $path) {
      fullWidthImage {
        shortDescription
        caption
        captionLink
        label
        imageCompound {
          image {
            variants {
              url
              size
            }
          }
        }
        link {
          adaDescription
          isNewWindow
          label
          url
        }
      }
    }
  }
}`,
  originalOpName: 'brand_CmsHgvMax',
};
export function useBrand_CmsHgvMaxQuery<TData = Types.Brand_CmsHgvMaxQuery, TError = unknown>(
  variables: Types.Brand_CmsHgvMaxQueryVariables,
  options?: Omit<UseQueryOptions<Types.Brand_CmsHgvMaxQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Brand_CmsHgvMaxQuery, TError, TData>({
    queryKey: [Brand_CmsHgvMaxDocument, variables],
    ...options,
  });
}
export function serverSideBrand_CmsHgvMaxQuery(
  queryClient: QueryClient,
  variables: Types.Brand_CmsHgvMaxQueryVariables,
  options?: FetchQueryOptions<Types.Brand_CmsHgvMaxQuery>
) {
  return queryClient.fetchQuery<Types.Brand_CmsHgvMaxQuery>({
    queryKey: [Brand_CmsHgvMaxDocument, variables],
    ...options,
  });
}

export const GuestHgvMaxDocument = {
  operationName: 'guest',
  operationString: `query guest($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    id: guestId
    guestId
    hhonors {
      isHGVMax
    }
  }
}`,
  originalOpName: 'guestHgvMax',
};
export function useGuestHgvMaxQuery<TData = Types.GuestHgvMaxQuery, TError = unknown>(
  variables: Types.GuestHgvMaxQueryVariables,
  options?: Omit<UseQueryOptions<Types.GuestHgvMaxQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GuestHgvMaxQuery, TError, TData>({
    queryKey: [GuestHgvMaxDocument, variables],
    ...options,
  });
}
export function serverSideGuestHgvMaxQuery(
  queryClient: QueryClient,
  variables: Types.GuestHgvMaxQueryVariables,
  options?: FetchQueryOptions<Types.GuestHgvMaxQuery>
) {
  return queryClient.fetchQuery<Types.GuestHgvMaxQuery>({
    queryKey: [GuestHgvMaxDocument, variables],
    ...options,
  });
}

export const HonorsTierBenefitsDocument = {
  operationName: 'honorsTierBenefits',
  operationString: `query honorsTierBenefits($language: String!) {
  honorsTierBenefits(language: $language) {
    benefitTier
    benefitTierName
    headline
    description
    image {
      altText
      variants {
        url
        device
      }
    }
    subheading
    items {
      headline
      shortDescription
      iconType
      description
    }
  }
}`,
  originalOpName: 'honorsTierBenefits',
};
export function useHonorsTierBenefitsQuery<TData = Types.HonorsTierBenefitsQuery, TError = unknown>(
  variables: Types.HonorsTierBenefitsQueryVariables,
  options?: Omit<UseQueryOptions<Types.HonorsTierBenefitsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HonorsTierBenefitsQuery, TError, TData>({
    queryKey: [HonorsTierBenefitsDocument, variables],
    ...options,
  });
}
export function serverSideHonorsTierBenefitsQuery(
  queryClient: QueryClient,
  variables: Types.HonorsTierBenefitsQueryVariables,
  options?: FetchQueryOptions<Types.HonorsTierBenefitsQuery>
) {
  return queryClient.fetchQuery<Types.HonorsTierBenefitsQuery>({
    queryKey: [HonorsTierBenefitsDocument, variables],
    ...options,
  });
}

export const CreateGuestDocument = {
  operationName: 'createGuest',
  operationString: `mutation createGuest($input: EnrollInput!, $language: String!, $recaptchaInput: RecaptchaInput) {
  createGuest(input: $input, language: $language, recaptchaInput: $recaptchaInput) {
    data {
      guestId
      hhonorsNumber
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'createGuest',
};
export function useCreateGuestMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateGuestMutation,
    TError,
    [typeof CreateGuestDocument, Types.CreateGuestMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateGuestMutation,
    TError,
    [typeof CreateGuestDocument, Types.CreateGuestMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const LanguagesDocument = {
  operationName: 'languages',
  operationString: `query languages($language: String!) {
  languages(language: $language, sort: [{by: languageName}]) {
    languageCode
    languageName
  }
}`,
  originalOpName: 'languages',
};
export function useLanguagesQuery<TData = Types.LanguagesQuery, TError = unknown>(
  variables: Types.LanguagesQueryVariables,
  options?: Omit<UseQueryOptions<Types.LanguagesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.LanguagesQuery, TError, TData>({
    queryKey: [LanguagesDocument, variables],
    ...options,
  });
}
export function serverSideLanguagesQuery(
  queryClient: QueryClient,
  variables: Types.LanguagesQueryVariables,
  options?: FetchQueryOptions<Types.LanguagesQuery>
) {
  return queryClient.fetchQuery<Types.LanguagesQuery>({
    queryKey: [LanguagesDocument, variables],
    ...options,
  });
}

export const BrandNonMemberPointsPageDocument = {
  operationName: 'brandNonMemberPointsPage',
  operationString: `query brandNonMemberPointsPage($language: String!, $brandCode: String!) {
  brand(language: $language, brandCode: $brandCode) {
    page(path: "points") {
      fullWidthImage {
        headline
        label
        shortDescription
        link {
          adaDescription
          isNewWindow
          label
          url
        }
        imageCompound {
          image {
            altText
            variants {
              size
              url
            }
          }
        }
      }
      multicolumnText {
        description
        items {
          headline
          items {
            description
            label
          }
        }
      }
      tabbedIcons {
        id
        description
        headline
        items {
          _id
          label
          iconType
          description
          headline
          link {
            adaDescription
            isNewWindow
            label
            url
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brandNonMemberPointsPage',
};
export function useBrandNonMemberPointsPageQuery<
  TData = Types.BrandNonMemberPointsPageQuery,
  TError = unknown
>(
  variables: Types.BrandNonMemberPointsPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandNonMemberPointsPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandNonMemberPointsPageQuery, TError, TData>({
    queryKey: [BrandNonMemberPointsPageDocument, variables],
    ...options,
  });
}
export function serverSideBrandNonMemberPointsPageQuery(
  queryClient: QueryClient,
  variables: Types.BrandNonMemberPointsPageQueryVariables,
  options?: FetchQueryOptions<Types.BrandNonMemberPointsPageQuery>
) {
  return queryClient.fetchQuery<Types.BrandNonMemberPointsPageQuery>({
    queryKey: [BrandNonMemberPointsPageDocument, variables],
    ...options,
  });
}

export const BrandPointsExplorerPageDocument = {
  operationName: 'brandPointsExplorerPage',
  operationString: `query brandPointsExplorerPage($language: String!) {
  brand(language: $language, brandCode: "HH") {
    page(path: "points-explorer") {
      fullWidthImage {
        headline
        label
        shortDescription
        link {
          adaDescription
          isNewWindow
          label
          url
        }
        imageCompound {
          image {
            altText
            variants(sort: {by: size}) {
              size
              url
            }
          }
        }
      }
    }
  }
}`,
  originalOpName: 'brandPointsExplorerPage',
};
export function useBrandPointsExplorerPageQuery<
  TData = Types.BrandPointsExplorerPageQuery,
  TError = unknown
>(
  variables: Types.BrandPointsExplorerPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.BrandPointsExplorerPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.BrandPointsExplorerPageQuery, TError, TData>({
    queryKey: [BrandPointsExplorerPageDocument, variables],
    ...options,
  });
}
export function serverSideBrandPointsExplorerPageQuery(
  queryClient: QueryClient,
  variables: Types.BrandPointsExplorerPageQueryVariables,
  options?: FetchQueryOptions<Types.BrandPointsExplorerPageQuery>
) {
  return queryClient.fetchQuery<Types.BrandPointsExplorerPageQuery>({
    queryKey: [BrandPointsExplorerPageDocument, variables],
    ...options,
  });
}

export const PointsExplorerFaqDocument = {
  operationName: 'faq',
  operationString: `query faq($language: String!) {
  faq(language: $language, faqType: pointsExplorer) {
    title
    subHeading
    shortDescription
    subTopic {
      subTopicName
      faqContent {
        question
        answer(format: md)
        answerOrderedList(format: md)
        answerUnorderedList(format: md)
      }
    }
  }
}`,
  originalOpName: 'pointsExplorerFaq',
};
export function usePointsExplorerFaqQuery<TData = Types.PointsExplorerFaqQuery, TError = unknown>(
  variables: Types.PointsExplorerFaqQueryVariables,
  options?: Omit<UseQueryOptions<Types.PointsExplorerFaqQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.PointsExplorerFaqQuery, TError, TData>({
    queryKey: [PointsExplorerFaqDocument, variables],
    ...options,
  });
}
export function serverSidePointsExplorerFaqQuery(
  queryClient: QueryClient,
  variables: Types.PointsExplorerFaqQueryVariables,
  options?: FetchQueryOptions<Types.PointsExplorerFaqQuery>
) {
  return queryClient.fetchQuery<Types.PointsExplorerFaqQuery>({
    queryKey: [PointsExplorerFaqDocument, variables],
    ...options,
  });
}

export const ProgramAccountDashboardSummaryDocument = {
  operationName: 'programAccountDashboardSummary',
  operationString: `query programAccountDashboardSummary($language: String!, $guestId: BigInt!, $accountId: Int!) {
  programAccountDashboardSummary(
    language: $language
    guestId: $guestId
    accountId: $accountId
  ) {
    honorsSummary {
      totalPoints
    }
    spendSummary {
      roomSpendSummary {
        roomSpendPastStaysFmt(decimal: 0, strategy: round)
        roomSpendFutureStaysFmt(decimal: 0, strategy: round)
      }
    }
  }
}`,
  originalOpName: 'programAccountDashboardSummary',
};
export function useProgramAccountDashboardSummaryQuery<
  TData = Types.ProgramAccountDashboardSummaryQuery,
  TError = unknown
>(
  variables: Types.ProgramAccountDashboardSummaryQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.ProgramAccountDashboardSummaryQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.ProgramAccountDashboardSummaryQuery, TError, TData>({
    queryKey: [ProgramAccountDashboardSummaryDocument, variables],
    ...options,
  });
}
export function serverSideProgramAccountDashboardSummaryQuery(
  queryClient: QueryClient,
  variables: Types.ProgramAccountDashboardSummaryQueryVariables,
  options?: FetchQueryOptions<Types.ProgramAccountDashboardSummaryQuery>
) {
  return queryClient.fetchQuery<Types.ProgramAccountDashboardSummaryQuery>({
    queryKey: [ProgramAccountDashboardSummaryDocument, variables],
    ...options,
  });
}

export const ProgramManagementPageDocument = {
  operationName: 'guest',
  operationString: `query guest($guestId: BigInt!, $language: String!) {
  guest(guestId: $guestId, language: $language) {
    hhonors {
      programAccountSummary {
        accountName
        accountId
        accountStatus
        memberStatus
        memberId
        accountInviteSummary {
          _id
          expireDate
          key
          link
        }
        memberRole
        memberEmailMasked
      }
    }
  }
}`,
  originalOpName: 'programManagementPage',
};
export function useProgramManagementPageQuery<
  TData = Types.ProgramManagementPageQuery,
  TError = unknown
>(
  variables: Types.ProgramManagementPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.ProgramManagementPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.ProgramManagementPageQuery, TError, TData>({
    queryKey: [ProgramManagementPageDocument, variables],
    ...options,
  });
}
export function serverSideProgramManagementPageQuery(
  queryClient: QueryClient,
  variables: Types.ProgramManagementPageQueryVariables,
  options?: FetchQueryOptions<Types.ProgramManagementPageQuery>
) {
  return queryClient.fetchQuery<Types.ProgramManagementPageQuery>({
    queryKey: [ProgramManagementPageDocument, variables],
    ...options,
  });
}

export const ResendTotpDocument = {
  operationName: 'resendTOTP',
  operationString: `mutation resendTOTP($deliveryId: Int!, $deliveryMethod: Guest2FADeliveryMethod!, $guestId: BigInt!, $language: String!) {
  createGuest2FATotp(
    guestId: $guestId
    language: $language
    deliveryMethod: $deliveryMethod
    deliveryId: $deliveryId
  ) {
    data
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'resendTOTP',
};
export function useResendTotpMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.ResendTotpMutation,
    TError,
    [typeof ResendTotpDocument, Types.ResendTotpMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.ResendTotpMutation,
    TError,
    [typeof ResendTotpDocument, Types.ResendTotpMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const Reservation_Hotel_DetailsDocument = {
  operationName: 'reservation_hotel_Details',
  operationString: `query reservation_hotel_Details($language: String!, $confNumber: String!) {
  reservation(language: $language, confNumber: $confNumber) {
    id: confNumber
    confNumber
    guest {
      name {
        firstName
        lastName
        title
      }
      phones(first: 1) {
        phoneNumberMasked(format: masked)
      }
      emails(first: 1) {
        emailAddressMasked @toLowerCase
      }
      addresses(first: 1) {
        addressFmt(format: "stacked")
        addressLine1
        addressLine2
        city
        country
        postalCode
        state
      }
      hhonorsNumber
    }
    hotel {
      ctyhocn
      name
    }
    arrivalDate
    arrivalDateFmt(format: "short", language: $language)
    departureDate
    departureDateFmt(format: "short", language: $language)
    rooms {
      resCreateDate
      roomType {
        roomTypeName
        numBeds
      }
    }
    totalNumAdults
    totalNumChildren
    cost {
      totalAmountBeforeTaxFmt(decimal: 2, format: "symbol")
      totalAmountBeforeTax
      currency {
        currencyCode
      }
    }
  }
}`,
  originalOpName: 'reservation_hotel_Details',
};
export function useReservation_Hotel_DetailsQuery<
  TData = Types.Reservation_Hotel_DetailsQuery,
  TError = unknown
>(
  variables: Types.Reservation_Hotel_DetailsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Reservation_Hotel_DetailsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Reservation_Hotel_DetailsQuery, TError, TData>({
    queryKey: [Reservation_Hotel_DetailsDocument, variables],
    ...options,
  });
}
export function serverSideReservation_Hotel_DetailsQuery(
  queryClient: QueryClient,
  variables: Types.Reservation_Hotel_DetailsQueryVariables,
  options?: FetchQueryOptions<Types.Reservation_Hotel_DetailsQuery>
) {
  return queryClient.fetchQuery<Types.Reservation_Hotel_DetailsQuery>({
    queryKey: [Reservation_Hotel_DetailsDocument, variables],
    ...options,
  });
}

export const CreateProgramAccountDocument = {
  operationName: 'createProgramAccount',
  operationString: `mutation createProgramAccount($language: String!, $guestId: BigInt!, $input: ProgramAccountsProgramAccountCreateRequestInput!) {
  createProgramAccount(language: $language, guestId: $guestId, input: $input) {
    data {
      memberId
      accountId
    }
    error {
      message
      code
    }
    notifications {
      message
      title
    }
  }
}`,
  originalOpName: 'createProgramAccount',
};
export function useCreateProgramAccountMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateProgramAccountMutation,
    TError,
    [typeof CreateProgramAccountDocument, Types.CreateProgramAccountMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateProgramAccountMutation,
    TError,
    [typeof CreateProgramAccountDocument, Types.CreateProgramAccountMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const Smb_CreateProgramAccountEmailTotpValidationDocument = {
  operationName: 'createProgramAccountEmailTotpValidation',
  operationString: `mutation createProgramAccountEmailTotpValidation($guestId: Int!, $language: String!, $input: ProgramAccountsSmbMemberInput!, $mfaInput: ProgramAccountsMemberTOTPValidationInput!) {
  createProgramAccountEmailTotpValidation(
    guestId: $guestId
    language: $language
    input: $input
    mfaInput: $mfaInput
  ) {
    _id
    data
    error {
      _id
      code
      context
      message
      notifications {
        _id
        code
        fields
        message
      }
    }
    notifications {
      _id
      message
      title
    }
  }
}`,
  originalOpName: 'smb_CreateProgramAccountEmailTotpValidation',
};
export function useSmb_CreateProgramAccountEmailTotpValidationMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.Smb_CreateProgramAccountEmailTotpValidationMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountEmailTotpValidationDocument,
      Types.Smb_CreateProgramAccountEmailTotpValidationMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_CreateProgramAccountEmailTotpValidationMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountEmailTotpValidationDocument,
      Types.Smb_CreateProgramAccountEmailTotpValidationMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const Smb_CreateProgramAccountEmailValidationDocument = {
  operationName: 'createProgramAccountEmailValidation',
  operationString: `mutation createProgramAccountEmailValidation($guestId: BigInt!, $language: String!, $accountId: Int!, $memberId: Int!) {
  createProgramAccountEmailValidation(
    guestId: $guestId
    language: $language
    input: {accountId: $accountId, memberId: $memberId}
  ) {
    data {
      accountId
      memberId
      status
    }
    error {
      _id
      code
      message
      notifications {
        code
        message
      }
    }
  }
}`,
  originalOpName: 'smb_CreateProgramAccountEmailValidation',
};
export function useSmb_CreateProgramAccountEmailValidationMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.Smb_CreateProgramAccountEmailValidationMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountEmailValidationDocument,
      Types.Smb_CreateProgramAccountEmailValidationMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_CreateProgramAccountEmailValidationMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountEmailValidationDocument,
      Types.Smb_CreateProgramAccountEmailValidationMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const Smb_CreateProgramAccountHonorsPointsTransferDocument = {
  operationName: 'createProgramAccountHonorsPointsTransfer',
  operationString: `mutation createProgramAccountHonorsPointsTransfer($guestId: BigInt!, $language: String!, $accountId: Int!, $input: ProgramAccountsHonorsPointsTransferInput!, $mfaInput: ProgramAccountsMFAInput) {
  createProgramAccountHonorsPointsTransfer(
    guestId: $guestId
    language: $language
    accountId: $accountId
    input: $input
    mfaInput: $mfaInput
  ) {
    data {
      _id
      accessToken
      status
    }
    notifications {
      title
      message
    }
    error {
      code
      message
      context
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'smb_CreateProgramAccountHonorsPointsTransfer',
};
export function useSmb_CreateProgramAccountHonorsPointsTransferMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.Smb_CreateProgramAccountHonorsPointsTransferMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountHonorsPointsTransferDocument,
      Types.Smb_CreateProgramAccountHonorsPointsTransferMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_CreateProgramAccountHonorsPointsTransferMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountHonorsPointsTransferDocument,
      Types.Smb_CreateProgramAccountHonorsPointsTransferMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const Smb_CreateProgramAccountInvitationLinkDocument = {
  operationName: 'createProgramAccountInvitationLink',
  operationString: `mutation createProgramAccountInvitationLink($guestId: BigInt!, $language: String!, $accountId: Int!) {
  createProgramAccountInvitationLink(
    guestId: $guestId
    language: $language
    accountId: $accountId
  ) {
    data
    error {
      code
      notifications {
        code
      }
    }
  }
}`,
  originalOpName: 'smb_CreateProgramAccountInvitationLink',
};
export function useSmb_CreateProgramAccountInvitationLinkMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.Smb_CreateProgramAccountInvitationLinkMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountInvitationLinkDocument,
      Types.Smb_CreateProgramAccountInvitationLinkMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_CreateProgramAccountInvitationLinkMutation,
    TError,
    [
      typeof Smb_CreateProgramAccountInvitationLinkDocument,
      Types.Smb_CreateProgramAccountInvitationLinkMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const CreateProgramAccountMemberDocument = {
  operationName: 'createProgramAccountMember',
  operationString: `mutation createProgramAccountMember($language: String!, $accountId: Int!, $input: ProgramAccountsProgramAccountMemberInput!) {
  createProgramAccountMember(
    language: $language
    accountId: $accountId
    input: $input
  ) {
    data {
      accountId
      memberId
      role
      status
      emailAddress: emailAddressMasked
    }
  }
}`,
  originalOpName: 'createProgramAccountMember',
};
export function useCreateProgramAccountMemberMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.CreateProgramAccountMemberMutation,
    TError,
    [typeof CreateProgramAccountMemberDocument, Types.CreateProgramAccountMemberMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.CreateProgramAccountMemberMutation,
    TError,
    [typeof CreateProgramAccountMemberDocument, Types.CreateProgramAccountMemberMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const Smb_DeleteProgramAccountMemberDocument = {
  operationName: 'deleteProgramAccountMember',
  operationString: `mutation deleteProgramAccountMember($guestId: BigInt!, $input: ProgramAccountsMemberIdInput!, $language: String!, $mfaInput: ProgramAccountsMFAInput) {
  deleteProgramAccountMember(
    guestId: $guestId
    input: $input
    language: $language
    mfaInput: $mfaInput
  ) {
    data {
      status
      accessToken
    }
    error {
      code
      context
      message
      message_noTx: message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'smb_DeleteProgramAccountMember',
};
export function useSmb_DeleteProgramAccountMemberMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.Smb_DeleteProgramAccountMemberMutation,
    TError,
    [
      typeof Smb_DeleteProgramAccountMemberDocument,
      Types.Smb_DeleteProgramAccountMemberMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_DeleteProgramAccountMemberMutation,
    TError,
    [
      typeof Smb_DeleteProgramAccountMemberDocument,
      Types.Smb_DeleteProgramAccountMemberMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const FaqDocument = {
  operationName: 'faq',
  operationString: `query faq($language: String!, $description: FaqType!) {
  faq(faqType: $description, language: $language) {
    title
    shortDescription
    subHeading
    subTopic {
      subTopicName
      faqContent {
        question
        answer(format: md)
        answerOrderedList
        answerUnorderedList
      }
    }
  }
}`,
  originalOpName: 'faq',
};
export function useFaqQuery<TData = Types.FaqQuery, TError = unknown>(
  variables: Types.FaqQueryVariables,
  options?: Omit<UseQueryOptions<Types.FaqQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FaqQuery, TError, TData>({
    queryKey: [FaqDocument, variables],
    ...options,
  });
}
export function serverSideFaqQuery(
  queryClient: QueryClient,
  variables: Types.FaqQueryVariables,
  options?: FetchQueryOptions<Types.FaqQuery>
) {
  return queryClient.fetchQuery<Types.FaqQuery>({ queryKey: [FaqDocument, variables], ...options });
}

export const Smb_FilterProgramAccountsDocument = {
  operationName: 'programAccounts',
  operationString: `query programAccounts($guestId: BigInt!, $language: String!, $accountId: BigInt!) {
  programAccounts(
    guestId: $guestId
    language: $language
    filter: {accountId: $accountId}
  ) {
    accountId
    memberStatus
    memberId
    accountName
    accountStatus
  }
}`,
  originalOpName: 'smb_FilterProgramAccounts',
};
export function useSmb_FilterProgramAccountsQuery<
  TData = Types.Smb_FilterProgramAccountsQuery,
  TError = unknown
>(
  variables: Types.Smb_FilterProgramAccountsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Smb_FilterProgramAccountsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Smb_FilterProgramAccountsQuery, TError, TData>({
    queryKey: [Smb_FilterProgramAccountsDocument, variables],
    ...options,
  });
}
export function serverSideSmb_FilterProgramAccountsQuery(
  queryClient: QueryClient,
  variables: Types.Smb_FilterProgramAccountsQueryVariables,
  options?: FetchQueryOptions<Types.Smb_FilterProgramAccountsQuery>
) {
  return queryClient.fetchQuery<Types.Smb_FilterProgramAccountsQuery>({
    queryKey: [Smb_FilterProgramAccountsDocument, variables],
    ...options,
  });
}

export const SmbMarketingBannerOffersDocument = {
  operationName: 'bannerOffers',
  operationString: `query bannerOffers($name: String!, $language: String!) {
  bannerOffers(name: $name, language: $language) {
    headline
    image {
      altText
      variants(sort: {by: size}) {
        size
        url
      }
    }
    label
    link {
      adaDescription
      isNewWindow
      label
      url
    }
    segmentIds
    shortDescription
  }
}`,
  originalOpName: 'smbMarketingBannerOffers',
};
export function useSmbMarketingBannerOffersQuery<
  TData = Types.SmbMarketingBannerOffersQuery,
  TError = unknown
>(
  variables: Types.SmbMarketingBannerOffersQueryVariables,
  options?: Omit<UseQueryOptions<Types.SmbMarketingBannerOffersQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.SmbMarketingBannerOffersQuery, TError, TData>({
    queryKey: [SmbMarketingBannerOffersDocument, variables],
    ...options,
  });
}
export function serverSideSmbMarketingBannerOffersQuery(
  queryClient: QueryClient,
  variables: Types.SmbMarketingBannerOffersQueryVariables,
  options?: FetchQueryOptions<Types.SmbMarketingBannerOffersQuery>
) {
  return queryClient.fetchQuery<Types.SmbMarketingBannerOffersQuery>({
    queryKey: [SmbMarketingBannerOffersDocument, variables],
    ...options,
  });
}

export const Smb_MemberIdAndStatusDocument = {
  operationName: 'programAccounts',
  operationString: `query programAccounts($guestId: BigInt!, $language: String!) {
  programAccounts(guestId: $guestId, language: $language) {
    accountId
    accountName
    accountStatus
    memberId
    memberStatus
  }
}`,
  originalOpName: 'smb_MemberIdAndStatus',
};
export function useSmb_MemberIdAndStatusQuery<
  TData = Types.Smb_MemberIdAndStatusQuery,
  TError = unknown
>(
  variables: Types.Smb_MemberIdAndStatusQueryVariables,
  options?: Omit<UseQueryOptions<Types.Smb_MemberIdAndStatusQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Smb_MemberIdAndStatusQuery, TError, TData>({
    queryKey: [Smb_MemberIdAndStatusDocument, variables],
    ...options,
  });
}
export function serverSideSmb_MemberIdAndStatusQuery(
  queryClient: QueryClient,
  variables: Types.Smb_MemberIdAndStatusQueryVariables,
  options?: FetchQueryOptions<Types.Smb_MemberIdAndStatusQuery>
) {
  return queryClient.fetchQuery<Types.Smb_MemberIdAndStatusQuery>({
    queryKey: [Smb_MemberIdAndStatusDocument, variables],
    ...options,
  });
}

export const Smb_ProgramAccountDetailsDocument = {
  operationName: 'programAccount',
  operationString: `query programAccount($guestId: BigInt!, $language: String!, $accountId: Int!) {
  programAccount(guestId: $guestId, language: $language, accountId: $accountId) {
    accountId
    memberId
    invitationLink
    inviteKeyExpireDate
    inviteKeyExpireDateFmt(format: "long")
    status
    created {
      modificationTime
    }
    emailDomains
    contactInfo {
      name
      address {
        addressFmt(format: "stacked")
        addressLine1
        addressLine2
        country
        postalCode
        city
        state
      }
      phone {
        phoneNumberMasked(format: masked)
        phoneExtension
        phoneCountry
      }
      websiteDomain
    }
  }
}`,
  originalOpName: 'smb_ProgramAccountDetails',
};
export function useSmb_ProgramAccountDetailsQuery<
  TData = Types.Smb_ProgramAccountDetailsQuery,
  TError = unknown
>(
  variables: Types.Smb_ProgramAccountDetailsQueryVariables,
  options?: Omit<UseQueryOptions<Types.Smb_ProgramAccountDetailsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Smb_ProgramAccountDetailsQuery, TError, TData>({
    queryKey: [Smb_ProgramAccountDetailsDocument, variables],
    ...options,
  });
}
export function serverSideSmb_ProgramAccountDetailsQuery(
  queryClient: QueryClient,
  variables: Types.Smb_ProgramAccountDetailsQueryVariables,
  options?: FetchQueryOptions<Types.Smb_ProgramAccountDetailsQuery>
) {
  return queryClient.fetchQuery<Types.Smb_ProgramAccountDetailsQuery>({
    queryKey: [Smb_ProgramAccountDetailsDocument, variables],
    ...options,
  });
}

export const Smb_ProgramDataFromInviteKeyDocument = {
  operationName: 'programAccountInviteKey',
  operationString: `query programAccountInviteKey($guestId: BigInt!, $inviteKey: String!, $language: String!) {
  programAccountInviteKey(
    guestId: $guestId
    inviteKey: $inviteKey
    language: $language
  ) {
    accountName
    accountId
    memberStatus
    memberId
    emailDomains
  }
}`,
  originalOpName: 'smb_ProgramDataFromInviteKey',
};
export function useSmb_ProgramDataFromInviteKeyQuery<
  TData = Types.Smb_ProgramDataFromInviteKeyQuery,
  TError = unknown
>(
  variables: Types.Smb_ProgramDataFromInviteKeyQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Smb_ProgramDataFromInviteKeyQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Smb_ProgramDataFromInviteKeyQuery, TError, TData>({
    queryKey: [Smb_ProgramDataFromInviteKeyDocument, variables],
    ...options,
  });
}
export function serverSideSmb_ProgramDataFromInviteKeyQuery(
  queryClient: QueryClient,
  variables: Types.Smb_ProgramDataFromInviteKeyQueryVariables,
  options?: FetchQueryOptions<Types.Smb_ProgramDataFromInviteKeyQuery>
) {
  return queryClient.fetchQuery<Types.Smb_ProgramDataFromInviteKeyQuery>({
    queryKey: [Smb_ProgramDataFromInviteKeyDocument, variables],
    ...options,
  });
}

export const Smb_ProgramAccountMemberDocument = {
  operationName: 'programAccountMember',
  operationString: `query programAccountMember($guestId: BigInt!, $language: String!, $input: ProgramAccountsSmbMemberInput!) {
  programAccountMember(guestId: $guestId, language: $language, input: $input) {
    accountId
    guestId
    emailAddress: emailAddressMasked
    emailLastVerifiedDateFmt(language: $language, format: "short")
    memberId
    status
    role
  }
}`,
  originalOpName: 'smb_ProgramAccountMember',
};
export function useSmb_ProgramAccountMemberQuery<
  TData = Types.Smb_ProgramAccountMemberQuery,
  TError = unknown
>(
  variables: Types.Smb_ProgramAccountMemberQueryVariables,
  options?: Omit<UseQueryOptions<Types.Smb_ProgramAccountMemberQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.Smb_ProgramAccountMemberQuery, TError, TData>({
    queryKey: [Smb_ProgramAccountMemberDocument, variables],
    ...options,
  });
}
export function serverSideSmb_ProgramAccountMemberQuery(
  queryClient: QueryClient,
  variables: Types.Smb_ProgramAccountMemberQueryVariables,
  options?: FetchQueryOptions<Types.Smb_ProgramAccountMemberQuery>
) {
  return queryClient.fetchQuery<Types.Smb_ProgramAccountMemberQuery>({
    queryKey: [Smb_ProgramAccountMemberDocument, variables],
    ...options,
  });
}

export const Smb_ProgramAccountMembersListDocument = {
  operationName: 'programAccountMembers',
  operationString: `query programAccountMembers($guestId: BigInt!, $input: ProgramAccountsProgramAccountMembersInput!, $language: String!, $after: String!) {
  _programAccountMembers {
    start
    end
    totalSize
    nextCursor
    prevCursor
  }
  programAccountMembers(
    guestId: $guestId
    input: $input
    language: $language
    first: 10
    after: $after
  ) {
    _id
    emailAddress
    role
    memberId
    status
    guestId
  }
}`,
  originalOpName: 'smb_ProgramAccountMembersList',
};
export function useSmb_ProgramAccountMembersListQuery<
  TData = Types.Smb_ProgramAccountMembersListQuery,
  TError = unknown
>(
  variables: Types.Smb_ProgramAccountMembersListQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Smb_ProgramAccountMembersListQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Smb_ProgramAccountMembersListQuery, TError, TData>({
    queryKey: [Smb_ProgramAccountMembersListDocument, variables],
    ...options,
  });
}
export function serverSideSmb_ProgramAccountMembersListQuery(
  queryClient: QueryClient,
  variables: Types.Smb_ProgramAccountMembersListQueryVariables,
  options?: FetchQueryOptions<Types.Smb_ProgramAccountMembersListQuery>
) {
  return queryClient.fetchQuery<Types.Smb_ProgramAccountMembersListQuery>({
    queryKey: [Smb_ProgramAccountMembersListDocument, variables],
    ...options,
  });
}

export const Smb_ProgramAccountNumberOfMembersByTypeDocument = {
  operationName: 'programAccountMembers',
  operationString: `query programAccountMembers($guestId: BigInt!, $input: ProgramAccountsProgramAccountMembersInput!, $language: String!, $after: String!) {
  _programAccountMembers {
    size
  }
  programAccountMembers(
    guestId: $guestId
    input: $input
    language: $language
    after: $after
  ) {
    _id
  }
}`,
  originalOpName: 'smb_ProgramAccountNumberOfMembersByType',
};
export function useSmb_ProgramAccountNumberOfMembersByTypeQuery<
  TData = Types.Smb_ProgramAccountNumberOfMembersByTypeQuery,
  TError = unknown
>(
  variables: Types.Smb_ProgramAccountNumberOfMembersByTypeQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Smb_ProgramAccountNumberOfMembersByTypeQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Smb_ProgramAccountNumberOfMembersByTypeQuery, TError, TData>({
    queryKey: [Smb_ProgramAccountNumberOfMembersByTypeDocument, variables],
    ...options,
  });
}
export function serverSideSmb_ProgramAccountNumberOfMembersByTypeQuery(
  queryClient: QueryClient,
  variables: Types.Smb_ProgramAccountNumberOfMembersByTypeQueryVariables,
  options?: FetchQueryOptions<Types.Smb_ProgramAccountNumberOfMembersByTypeQuery>
) {
  return queryClient.fetchQuery<Types.Smb_ProgramAccountNumberOfMembersByTypeQuery>({
    queryKey: [Smb_ProgramAccountNumberOfMembersByTypeDocument, variables],
    ...options,
  });
}

export const ProgramAccountInviteKeyDocument = {
  operationName: 'programAccountInviteKey',
  operationString: `query programAccountInviteKey($guestId: BigInt!, $language: String!, $inviteKey: String!) {
  programAccountInviteKey(
    guestId: $guestId
    language: $language
    inviteKey: $inviteKey
  ) {
    accountStatus
    accountId
    accountName
    memberId
    memberStatus
  }
}`,
  originalOpName: 'programAccountInviteKey',
};
export function useProgramAccountInviteKeyQuery<
  TData = Types.ProgramAccountInviteKeyQuery,
  TError = unknown
>(
  variables: Types.ProgramAccountInviteKeyQueryVariables,
  options?: Omit<UseQueryOptions<Types.ProgramAccountInviteKeyQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.ProgramAccountInviteKeyQuery, TError, TData>({
    queryKey: [ProgramAccountInviteKeyDocument, variables],
    ...options,
  });
}
export function serverSideProgramAccountInviteKeyQuery(
  queryClient: QueryClient,
  variables: Types.ProgramAccountInviteKeyQueryVariables,
  options?: FetchQueryOptions<Types.ProgramAccountInviteKeyQuery>
) {
  return queryClient.fetchQuery<Types.ProgramAccountInviteKeyQuery>({
    queryKey: [ProgramAccountInviteKeyDocument, variables],
    ...options,
  });
}

export const SmbTermsAndConditionsDocument = {
  operationName: 'policy',
  operationString: `query policy($language: String!) {
  policy(language: $language, policyDocumentType: smbTermsAndConditions) {
    name
    description
    shortDescription
    content {
      heading(format: md)
      description(format: md)
      orderedList(format: md)
      unorderedList(format: md)
      __typename
    }
    __typename
  }
}`,
  originalOpName: 'smbTermsAndConditions',
};
export function useSmbTermsAndConditionsQuery<
  TData = Types.SmbTermsAndConditionsQuery,
  TError = unknown
>(
  variables: Types.SmbTermsAndConditionsQueryVariables,
  options?: Omit<UseQueryOptions<Types.SmbTermsAndConditionsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.SmbTermsAndConditionsQuery, TError, TData>({
    queryKey: [SmbTermsAndConditionsDocument, variables],
    ...options,
  });
}
export function serverSideSmbTermsAndConditionsQuery(
  queryClient: QueryClient,
  variables: Types.SmbTermsAndConditionsQueryVariables,
  options?: FetchQueryOptions<Types.SmbTermsAndConditionsQuery>
) {
  return queryClient.fetchQuery<Types.SmbTermsAndConditionsQuery>({
    queryKey: [SmbTermsAndConditionsDocument, variables],
    ...options,
  });
}

export const Smb_UpdateProgramAccountDocument = {
  operationName: 'updateProgramAccount',
  operationString: `mutation updateProgramAccount($guestId: BigInt!, $language: String!, $accountId: Int!, $input: ProgramAccountsProgramAccountUpdateRequestInput!, $mfaInput: ProgramAccountsMFAInput) {
  updateProgramAccount(
    guestId: $guestId
    language: $language
    accountId: $accountId
    input: $input
    mfaInput: $mfaInput
  ) {
    data {
      accountId
      accountStatus
      accessToken
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'smb_UpdateProgramAccount',
};
export function useSmb_UpdateProgramAccountMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.Smb_UpdateProgramAccountMutation,
    TError,
    [typeof Smb_UpdateProgramAccountDocument, Types.Smb_UpdateProgramAccountMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_UpdateProgramAccountMutation,
    TError,
    [typeof Smb_UpdateProgramAccountDocument, Types.Smb_UpdateProgramAccountMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const Smb_UpdateProgramAccountMemberEmailDocument = {
  operationName: 'updateProgramAccountMember',
  operationString: `mutation updateProgramAccountMember($accountId: Int!, $language: String!, $memberId: Int!, $input: ProgramAccountsUpdateMemberInput!) {
  updateProgramAccountMember(
    accountId: $accountId
    language: $language
    memberId: $memberId
    input: $input
  ) {
    data {
      emailAddressMasked
    }
    error {
      code
      message
      message_noTx: message
    }
  }
}`,
  originalOpName: 'smb_UpdateProgramAccountMemberEmail',
};
export function useSmb_UpdateProgramAccountMemberEmailMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.Smb_UpdateProgramAccountMemberEmailMutation,
    TError,
    [
      typeof Smb_UpdateProgramAccountMemberEmailDocument,
      Types.Smb_UpdateProgramAccountMemberEmailMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_UpdateProgramAccountMemberEmailMutation,
    TError,
    [
      typeof Smb_UpdateProgramAccountMemberEmailDocument,
      Types.Smb_UpdateProgramAccountMemberEmailMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const Smb_UpdateProgramAccountMemberEmailTotpDocument = {
  operationName: 'updateProgramAccountMember',
  operationString: `mutation updateProgramAccountMember($accountId: Int!, $language: String!, $memberId: Int!, $input: ProgramAccountsUpdateMemberInput!, $mfaInput: ProgramAccountsMFAInput!) {
  updateProgramAccountMember(
    accountId: $accountId
    language: $language
    memberId: $memberId
    input: $input
    mfaInput: $mfaInput
  ) {
    data {
      accessToken
      emailAddressMasked
      status
    }
    error {
      code
      message
      message_noTx: message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'smb_UpdateProgramAccountMemberEmailTotp',
};
export function useSmb_UpdateProgramAccountMemberEmailTotpMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.Smb_UpdateProgramAccountMemberEmailTotpMutation,
    TError,
    [
      typeof Smb_UpdateProgramAccountMemberEmailTotpDocument,
      Types.Smb_UpdateProgramAccountMemberEmailTotpMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.Smb_UpdateProgramAccountMemberEmailTotpMutation,
    TError,
    [
      typeof Smb_UpdateProgramAccountMemberEmailTotpDocument,
      Types.Smb_UpdateProgramAccountMemberEmailTotpMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const TravelPartnersDocument = {
  operationName: 'travelPartners',
  operationString: `query travelPartners($language: String!) {
  travelPartners(language: $language, sort: {by: partnerName}) {
    partnerCode
    partnerName
  }
}`,
  originalOpName: 'travelPartners',
};
export function useTravelPartnersQuery<TData = Types.TravelPartnersQuery, TError = unknown>(
  variables: Types.TravelPartnersQueryVariables,
  options?: Omit<UseQueryOptions<Types.TravelPartnersQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.TravelPartnersQuery, TError, TData>({
    queryKey: [TravelPartnersDocument, variables],
    ...options,
  });
}
export function serverSideTravelPartnersQuery(
  queryClient: QueryClient,
  variables: Types.TravelPartnersQueryVariables,
  options?: FetchQueryOptions<Types.TravelPartnersQuery>
) {
  return queryClient.fetchQuery<Types.TravelPartnersQuery>({
    queryKey: [TravelPartnersDocument, variables],
    ...options,
  });
}

export const UpdateGuestAccountPreferencesDocument = {
  operationName: 'updateGuestAccountPreferences',
  operationString: `mutation updateGuestAccountPreferences($language: String!, $guestId: BigInt!, $input: GuestAccountPreferencesInput!) {
  updateGuestAccountPreferences(
    guestId: $guestId
    input: $input
    language: $language
  ) {
    data {
      roomprefs {
        accessible
        bedtype
        closeToElevator
        highFloor
        mostImportant
        smoking
      }
      travelPartners {
        active
        enrollmentDate
        enrollmentDateFmt
        expireDate
        expireDateFmt
        partnerCode
        partnerName
        partnerNumber
        partnerType
        preferred
      }
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
    notifications {
      message
      title
    }
  }
}`,
  originalOpName: 'updateGuestAccountPreferences',
};
export function useUpdateGuestAccountPreferencesMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestAccountPreferencesMutation,
    TError,
    [
      typeof UpdateGuestAccountPreferencesDocument,
      Types.UpdateGuestAccountPreferencesMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestAccountPreferencesMutation,
    TError,
    [
      typeof UpdateGuestAccountPreferencesDocument,
      Types.UpdateGuestAccountPreferencesMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestAddressesDocument = {
  operationName: 'updateGuestAddresses',
  operationString: `mutation updateGuestAddresses($guestId: BigInt!, $language: String!, $input: [GuestAddressInput]!) {
  updateGuestAddresses(guestId: $guestId, language: $language, input: $input) {
    data {
      addressId
      addressLine1
      addressLine2
      addressType
      city
      company
      country
      postalCode
      preferred
      state
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'updateGuestAddresses',
};
export function useUpdateGuestAddressesMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestAddressesMutation,
    TError,
    [typeof UpdateGuestAddressesDocument, Types.UpdateGuestAddressesMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestAddressesMutation,
    TError,
    [typeof UpdateGuestAddressesDocument, Types.UpdateGuestAddressesMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestBenefitPreferencesDocument = {
  operationName: 'updateGuestBenefitPreferences',
  operationString: `mutation updateGuestBenefitPreferences($input: [GuestBenefitPreferencesInput]!, $guestId: BigInt!) {
  updateGuestBenefitPreferences(guestId: $guestId, input: $input) {
    data {
      brandCode
      benefitId
      benefitValue
    }
    error {
      code
      notifications {
        code
        message
        message
      }
      message
      code
    }
  }
}`,
  originalOpName: 'updateGuestBenefitPreferences',
};
export function useUpdateGuestBenefitPreferencesMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestBenefitPreferencesMutation,
    TError,
    [
      typeof UpdateGuestBenefitPreferencesDocument,
      Types.UpdateGuestBenefitPreferencesMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestBenefitPreferencesMutation,
    TError,
    [
      typeof UpdateGuestBenefitPreferencesDocument,
      Types.UpdateGuestBenefitPreferencesMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestEmailsDocument = {
  operationName: 'updateGuestEmails',
  operationString: `mutation updateGuestEmails($guestId: BigInt!, $language: String!, $input: [GuestEmailInput]!, $totp: String!) {
  updateGuestEmails(
    guestId: $guestId
    language: $language
    input: $input
    mfaInput: {totp: $totp}
  ) {
    data {
      emailId
      emailAddressMasked
      preferred
    }
    error {
      code
      context
      message
      message_noTx: message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'updateGuestEmails',
};
export function useUpdateGuestEmailsMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestEmailsMutation,
    TError,
    [typeof UpdateGuestEmailsDocument, Types.UpdateGuestEmailsMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestEmailsMutation,
    TError,
    [typeof UpdateGuestEmailsDocument, Types.UpdateGuestEmailsMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestPasswordDocument = {
  operationName: 'updateGuestPassword',
  operationString: `mutation updateGuestPassword($guestId: BigInt!, $input: GuestSetPasswordInput!, $language: String!) {
  updateGuestPassword(language: $language, guestId: $guestId, input: $input) {
    data
  }
}`,
  originalOpName: 'updateGuestPassword',
};
export function useUpdateGuestPasswordMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestPasswordMutation,
    TError,
    [typeof UpdateGuestPasswordDocument, Types.UpdateGuestPasswordMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestPasswordMutation,
    TError,
    [typeof UpdateGuestPasswordDocument, Types.UpdateGuestPasswordMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestPaymentMethodsDocument = {
  operationName: 'updateGuestPaymentMethods',
  operationString: `mutation updateGuestPaymentMethods($guestId: BigInt!, $language: String!, $input: [GuestPaymentMethodInput]!) {
  updateGuestPaymentMethods(guestId: $guestId, language: $language, input: $input) {
    data {
      paymentId
      cardCode
      cardName
      cardExpireDate
      lastFour: cardNumberMasked(format: lastFour)
      cardNumberMasked: cardNumberMasked(format: masked)
      cardExpireDateMed: cardExpireDateFmt(format: "medium")
      cardExpireDateLong: cardExpireDateFmt(format: "long")
      expired
      preferred
    }
    error {
      code
      message
      context
      notifications {
        message
        code
        fields
      }
    }
  }
}`,
  originalOpName: 'updateGuestPaymentMethods',
};
export function useUpdateGuestPaymentMethodsMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestPaymentMethodsMutation,
    TError,
    [typeof UpdateGuestPaymentMethodsDocument, Types.UpdateGuestPaymentMethodsMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestPaymentMethodsMutation,
    TError,
    [typeof UpdateGuestPaymentMethodsDocument, Types.UpdateGuestPaymentMethodsMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestPhoneNumbersDocument = {
  operationName: 'updateGuestPhoneNumbers',
  operationString: `mutation updateGuestPhoneNumbers($guestId: BigInt!, $language: String!, $input: [GuestPhoneInput]!, $totp: String!) {
  updateGuestPhoneNumbers(
    guestId: $guestId
    language: $language
    input: $input
    mfaInput: {totp: $totp}
  ) {
    data {
      phoneId
      phoneType
      phoneExtension
      phoneNumberMasked(format: masked)
      preferred
      validated
      phoneNumber2FAStatus
      phoneCountry
    }
    error {
      code
      context
      message
      message_noTx: message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'updateGuestPhoneNumbers',
};
export function useUpdateGuestPhoneNumbersMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestPhoneNumbersMutation,
    TError,
    [typeof UpdateGuestPhoneNumbersDocument, Types.UpdateGuestPhoneNumbersMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestPhoneNumbersMutation,
    TError,
    [typeof UpdateGuestPhoneNumbersDocument, Types.UpdateGuestPhoneNumbersMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestPreferredLanguageDocument = {
  operationName: 'updateGuestPersonalizations',
  operationString: `mutation updateGuestPersonalizations($language: String!, $guestId: BigInt!, $input: GuestPersonalizationsInput!) {
  updateGuestPersonalizations(
    language: $language
    guestId: $guestId
    input: $input
  ) {
    data {
      preferredLanguage
    }
    error {
      message
      context
      notifications {
        message
        fields
      }
    }
  }
}`,
  originalOpName: 'updateGuestPreferredLanguage',
};
export function useUpdateGuestPreferredLanguageMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestPreferredLanguageMutation,
    TError,
    [
      typeof UpdateGuestPreferredLanguageDocument,
      Types.UpdateGuestPreferredLanguageMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestPreferredLanguageMutation,
    TError,
    [
      typeof UpdateGuestPreferredLanguageDocument,
      Types.UpdateGuestPreferredLanguageMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestTravelAccountsDocument = {
  operationName: 'updateGuestTravelAccounts',
  operationString: `mutation updateGuestTravelAccounts($guestId: BigInt!, $language: String!, $input: GuestTravelAccountsInput!) {
  updateGuestTravelAccounts(guestId: $guestId, language: $language, input: $input) {
    data {
      corporateAccount
      travelAgentNumber
      unlimitedBudgetNumber
      aarpNumber
      aaaNumber
      aaaInternationalNumber
      travelAgentNumber
      governmentMilitary
    }
    error {
      code
      message
    }
  }
}`,
  originalOpName: 'updateGuestTravelAccounts',
};
export function useUpdateGuestTravelAccountsMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestTravelAccountsMutation,
    TError,
    [typeof UpdateGuestTravelAccountsDocument, Types.UpdateGuestTravelAccountsMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestTravelAccountsMutation,
    TError,
    [typeof UpdateGuestTravelAccountsDocument, Types.UpdateGuestTravelAccountsMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestUsernameDocument = {
  operationName: 'updateGuestUsername',
  operationString: `mutation updateGuestUsername($guestId: BigInt!, $language: String!, $confirmNewUsername: String!, $newUsername: String!) {
  updateGuestUsername(
    guestId: $guestId
    language: $language
    input: {confirmNewUsername: $confirmNewUsername, newUsername: $newUsername}
  ) {
    data
    error {
      code
    }
  }
}`,
  originalOpName: 'updateGuestUsername',
};
export function useUpdateGuestUsernameMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateGuestUsernameMutation,
    TError,
    [typeof UpdateGuestUsernameDocument, Types.UpdateGuestUsernameMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestUsernameMutation,
    TError,
    [typeof UpdateGuestUsernameDocument, Types.UpdateGuestUsernameMutationVariables],
    TContext
  >({
    ...options,
  });
}
export const UpdateGuestMarketingSubscriptionOptOutDocument = {
  operationName: 'updateGuestMarketingSubscriptionOptOut',
  operationString: `mutation updateGuestMarketingSubscriptionOptOut($language: String!, $email: String!) {
  updateGuestMarketingSubscriptionOptOut(
    language: $language
    emailAddress: $email
  ) {
    data
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'updateGuestMarketingSubscriptionOptOut',
};
export function useUpdateGuestMarketingSubscriptionOptOutMutation<
  TError = unknown,
  TContext = unknown
>(
  options: UseMutationOptions<
    Types.UpdateGuestMarketingSubscriptionOptOutMutation,
    TError,
    [
      typeof UpdateGuestMarketingSubscriptionOptOutDocument,
      Types.UpdateGuestMarketingSubscriptionOptOutMutationVariables
    ],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateGuestMarketingSubscriptionOptOutMutation,
    TError,
    [
      typeof UpdateGuestMarketingSubscriptionOptOutDocument,
      Types.UpdateGuestMarketingSubscriptionOptOutMutationVariables
    ],
    TContext
  >({
    ...options,
  });
}
export const UpdateSubscriptionsDocument = {
  operationName: 'updateSubscriptions',
  operationString: `mutation updateSubscriptions($language: String!, $guestId: BigInt!, $input: SubscriptionsInput!) {
  updateSubscriptions(language: $language, guestId: $guestId, input: $input) {
    data {
      subscriptions {
        optOuts {
          survey
          marketing
          global
        }
        marketingSubscriptions
        hhonorsSubscriptions
      }
    }
    error {
      code
      context
      message
      notifications {
        code
        fields
        message
      }
    }
  }
}`,
  originalOpName: 'updateSubscriptions',
};
export function useUpdateSubscriptionsMutation<TError = unknown, TContext = unknown>(
  options: UseMutationOptions<
    Types.UpdateSubscriptionsMutation,
    TError,
    [typeof UpdateSubscriptionsDocument, Types.UpdateSubscriptionsMutationVariables],
    TContext
  > = {}
) {
  return useMutation<
    Types.UpdateSubscriptionsMutation,
    TError,
    [typeof UpdateSubscriptionsDocument, Types.UpdateSubscriptionsMutationVariables],
    TContext
  >({
    ...options,
  });
}
