/* eslint-disable no-console */
import { useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import '../styles/global.css';
import { AuthProvider } from '@dx-ui/framework-auth-provider';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import { useTranslation } from 'next-i18next';
import { GlobalStyle } from '../styles/app';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Layout } from '../components/Layout';
import { MetricsProvider } from '../config/metrics';
import { auth, IDLE_TIMEOUT } from '../utils/auth';
import { getLoginRedirectUrl, getUrlHost } from '../utils/helpers/urlBuilder';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { makeQueryClient } from '@dx-ui/framework-react-query';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';

import { REACT_QUERY_CONFIG } from '../config/react-query';
import { useManualPageLogger } from '@dx-ui/framework-logger';
const { LOCATION_PROVIDER_API, APP_ENV, BASE_URL } = getConfig().publicRuntimeConfig;
const APP_NAME = 'dx-guests-ui';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { forwardUrl, metrics, layout, supportedLanguages, isSMBEmployee, hasCustomCanonicalUrl } =
    pageProps;
  const router = useRouter();
  useManualPageLogger(router.pathname, APP_NAME);

  const [t] = useTranslation('common');
  const routerLocale = useRouter().locale || router.locale || 'en';
  const authClient = useMemo(() => auth(), []);
  const reactQueryClient = useMemo(
    () =>
      makeQueryClient({
        appName: REACT_QUERY_CONFIG.appName,
        ssrMode: typeof window === 'undefined',
        oneLinkConfig: pageProps.oneLinkConfig,
        routerLocale,
        authClient,
      }),
    [authClient, routerLocale, pageProps.oneLinkConfig]
  );

  useEffect(() => {
    const handleStart: Parameters<typeof router.events.on>[1] = (url: string, { shallow }) => {
      // Exclude exchange-points sub routes
      if (!shallow && /\/[a-zA-Z]{2,5}\/hilton-honors\/guest\/(?!exchange-points\/\w)/.test(url)) {
        // Force full page load
        window.location.assign(url);
      }
    };

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    };
  }, [router]);

  // Sanitize url parameters via regex filter
  useEffect(() => {
    const url = router.asPath;
    const regexFilter = /(\?|&)(password|name|phone|language)/i;
    if (regexFilter.test(url)) {
      const sanitizedUrl = new URL(url, getUrlHost());
      Object.keys(router.query).forEach((key) => {
        if (regexFilter.test(key)) {
          sanitizedUrl.searchParams.delete(key);
        }
      });
      router
        .replace(sanitizedUrl, undefined, { shallow: true })
        .catch(() => console.error(`Error on navigating to ${sanitizedUrl.toString()}`));
    }
    // Do replace() only during page mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Logout timer when window is idle for 30 mins
  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    eventsThrottle: 1000,
    onIdle: async () => {
      if (/local|test/.test(APP_ENV)) console.log('[dx-guests-ui] - IDLE: ', Date.now().toString());
      await authClient.logout();
    },
    ...(/local|test/.test(APP_ENV)
      ? {
          onActive: () => {
            console.log('[dx-guests-ui] - ACTIVE: ', Date.now().toString());
          },
          onAction: () => {
            console.log('[dx-guests-ui] - ACTION: ', Date.now().toString());
          },
        }
      : {}),
  });

  const handleLogout = async () => {
    if (router?.asPath.includes('hgv-max')) {
      const hgvRedirectUrl = `/${router?.locale ?? 'en'}/hgv-max/`;
      try {
        await router.push(hgvRedirectUrl);
      } catch {
        // Force page reload if logout.
        window.location.assign(hgvRedirectUrl);
      }
    }

    if (router?.asPath.includes('go-hilton')) {
      const goHiltonRedirectUrl = `/${router?.locale ?? 'en'}/go-hilton/`;
      try {
        await router.push(goHiltonRedirectUrl);
      } catch {
        // Force page reload if logout.
        window.location.assign(goHiltonRedirectUrl);
      }
    }

    if (router?.asPath.includes('business') && !router?.asPath.includes('hilton-for-business')) {
      const smbLoginRedirectUrl = isSMBEmployee
        ? `/${router?.locale ?? 'en'}/business/member/login/`
        : `/${router?.locale ?? 'en'}/business/login/`;
      try {
        await router.push(smbLoginRedirectUrl);
      } catch {
        // Force page reload if logout.
        window.location.assign(smbLoginRedirectUrl);
      }
    }

    if (/hilton-honors\/guest/.test(router.asPath)) {
      const redirectUrl = /guest\/points/.test(router.asPath)
        ? `/${router?.locale ?? 'en'}/hilton-honors/points/`
        : getLoginRedirectUrl(router.asPath, router.query, router.locale as SupportedLanguage);
      try {
        await router.push(redirectUrl);
      } catch {
        // Force page reload if logout.
        window.location.assign(redirectUrl);
      }
    }
    return {};
  };

  return (
    <>
      <Head>
        <meta name="format-detection" content="telephone=no" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{t('hiltonHonors')}</title>
        {hasCustomCanonicalUrl ? null : (
          <link rel="canonical" href={`${BASE_URL}/${router?.locale}${router?.pathname}/`} />
        )}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${BASE_URL}/en${router?.asPath}`}
          key="x-default"
        />
        <link rel="alternate" hrefLang="en" href={`${BASE_URL}/en${router?.asPath}`} />
        {supportedLanguages?.map((language: string) => (
          <link
            rel="alternate"
            hrefLang={language}
            href={`${BASE_URL}/${language}${router?.asPath}`}
            key={`alternate-link-${language}`}
          />
        ))}
      </Head>
      <GlobalStyle language={router.locale || 'en'} />
      <AuthProvider authClient={authClient} onLogout={handleLogout}>
        <QueryClientProvider client={reactQueryClient}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <MetricsProvider trackingData={metrics?.trackingData}>
              <div id="main" className="App">
                <LocationProvider api={LOCATION_PROVIDER_API}>
                  <Layout userAgent={pageProps.userAgent} forwardUrl={forwardUrl} layout={layout}>
                    <Component {...pageProps} />
                  </Layout>
                </LocationProvider>
              </div>
            </MetricsProvider>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
          </HydrationBoundary>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
};

export default appWithTranslation(MyApp);
