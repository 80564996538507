import getConfig from 'next/config';

import { languageCodeRegEx } from '../constants/languages';
import { buildFullUrl } from '../routeHelpers';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { GetServerSidePropsContext } from 'next';
import { getServerSideSafeLanguage } from '@dx-ui/framework-react-query';
import type { AuthClient } from '@dx-ui/framework-auth-provider';
import { serverSideGuest_GetHHonorsNumberQuery } from '../../queries/generated/react-query';
import type { QueryClient } from '@tanstack/react-query';

const {
  publicRuntimeConfig: { APP_ENV, BASE_URL, PORT, REACT_APP_BRAND_HOST },
} = getConfig();

const isAnchorLink = (href: string): boolean => href.startsWith('#');

/**
 * Takes a URL string and returns a normalized path that can be checked against `IN_LANGUAGE_PAGES`
 * @example
 * getNormalizedPathNameFromUrl('https://www.hilton.com/fr/hilton-honors/points/')
 * // returns `/${language}/hilton-honors/points`
 * @returns {string} path name that matches pattern of IN_LANGUAGE_PAGES
 */
const getNormalizedPathNameFromUrl = (url: string | null) => {
  if (!url) return url;
  const decodedUrl = decodeURIComponent(url) !== url ? decodeURIComponent(url) : url;

  const matchedDecodedUrl = decodedUrl.match(new RegExp(languageCodeRegEx));
  let normalizedPathName = decodedUrl.replace(new RegExp(languageCodeRegEx), '');
  normalizedPathName = matchedDecodedUrl?.index
    ? normalizedPathName.slice(matchedDecodedUrl.index - 1)
    : normalizedPathName;
  const endOfPath = normalizedPathName.lastIndexOf('/');
  normalizedPathName = normalizedPathName.slice(0, endOfPath + 1);
  return normalizedPathName;
};

/**
 * Get base application url.
 */
const getUrlHost = (): string => {
  const includePort = /local/.test(APP_ENV);
  const port = includePort ? `:${PORT}` : '';
  const baseUrl = BASE_URL || REACT_APP_BRAND_HOST;
  return `${baseUrl}${port}`;
};

/**
 * Get full path for asset located in dx-assets-ui.
 * @param path - Asset path ('/modules/assets/fonts/loew/black.woff2')
 */
export const getAssetUrl = (path: string): string => {
  const host = getUrlHost();
  return `${host.includes('http') ? '' : 'https://'}${host}/modules/assets${path}`;
};

/**
 * Get OHW URLs and points to staging https://www.stg.hilton.com/en/locations/.
 * In production points to https://www.hilton.com/en/locations/
 * @param path - Page path ('/en/locations/')
 */
const getExternalUrl = (path: string): string => {
  const isProd = /prd/.test(APP_ENV);
  const host = isProd ? BASE_URL : 'https://www.stg.hilton.com';
  return `${host}${path}`;
};

/**
 * Get login url
 * @param forwardPageURI
 * @param language
 */
const getLoginUrl = ({
  language,
  forwardPageURI,
}: {
  language: string;
  forwardPageURI?: string | null;
}) => {
  const loginUrl = new URL(buildFullUrl(`/${language}/hilton-honors/login/`));
  if (forwardPageURI) {
    const refererUrl = new URL(forwardPageURI, buildFullUrl(''));
    const { searchParams } = refererUrl;
    searchParams.set('forwardPageURI', forwardPageURI);
    searchParams.delete('language');
    loginUrl.search = searchParams.toString();
  }

  return loginUrl;
};

/**
 * Get relative login url
 */
export const getLoginRedirectUrl = (
  resolvedUrl: string,
  query: ParsedUrlQuery,
  locale: SupportedLanguage
) => {
  const redirectSearchQuery = new URLSearchParams(query as Record<string, string>);
  redirectSearchQuery.set('forwardPageURI', encodeURIComponent(`/${locale}${resolvedUrl}`));
  return `https://${REACT_APP_BRAND_HOST}/${locale}/hilton-honors/login/?${redirectSearchQuery.toString()}`;
};

export const getServerSideLoginRedirectUrl = (context: GetServerSidePropsContext) => {
  const originalLocale = getServerSideSafeLanguage(context);
  const language = (originalLocale || context.locale) as SupportedLanguage;
  return getLoginRedirectUrl(context.resolvedUrl, context.query, language);
};

/**
 * Get application url (on dev/local environment include port)
 */
const getAppHostUrl = (): string => {
  const includePort = /local/.test(APP_ENV);
  const port = includePort ? `:${PORT}` : '';
  const baseUrl = BASE_URL || REACT_APP_BRAND_HOST;
  return `${baseUrl}${port}`;
};

const getGuestIdAndRedirectFn = async ({
  authClient,
  locale,
  queryClient,
  redirectUrl,
}: {
  authClient: AuthClient;
  locale: SupportedLanguage;
  queryClient: QueryClient;
  redirectUrl: string;
}) => {
  let guestId = authClient.getGuestId();

  if (guestId) {
    const data = await serverSideGuest_GetHHonorsNumberQuery(queryClient, {
      language: locale,
      guestId,
    }).catch(() => null);
    if (!data?.guest?.hhonors?.hhonorsNumber) {
      guestId = null;
    }
  }

  return {
    guestId,
    logoutAndRedirect: async () => {
      await authClient.logout();
      return {
        redirect: {
          permanent: false,
          destination: redirectUrl,
        },
      };
    },
  };
};

export {
  getAppHostUrl,
  getExternalUrl,
  getGuestIdAndRedirectFn,
  getLoginUrl,
  getNormalizedPathNameFromUrl,
  getUrlHost,
  isAnchorLink,
};
