import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import { dynatrace } from '@dx-ui/framework-logger';

import { Goals } from '../../types/Conductrics';

export const GoalByCardName = {
  'Hilton Honors': Goals.HonorsApplyNow,
  'Hilton Honors Surpass®': Goals.SurpassApplyNow,
  'Hilton Honors Aspire': Goals.AspireApplyNow,
  'Hilton Honors Business': Goals.BusinessApplyNow,
} as const;

export const sendConductricsReward = (goalID: ValuesOf<typeof Goals>) => {
  sendRewardAsync(goalID).catch((error) =>
    dynatrace?.reportCustomError('sendRewardError', error, `Reward ID: ${goalID}`, true)
  );
};
